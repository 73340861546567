import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavbarLayout from "./components/NavbarSidebar/NavbarLayout";
import LoginView from "./components/Login/LoginView";
import SignalRegisterView from "./components/Login/SignalRegisterView";
import SignalProvider from "./components/SignalProvider/SignalProvider";
import NavbarAndSidebarSignal from "./components/NavbarSidebar/NavbarAndSidebarSignal.js";
import NavbarAndSidebarFollower from "./components/NavbarSidebar/NavbarAndSidebarFollower.js";
import Follower from "./components/Follower/Follower";
import NotFound from "./components/404/notFound";
import CookieChecker from "./Tools/CookieChecker";
import React, { useMemo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { BreakpointProvider } from "./config/BreakpointContext.js";

export const baseEnv = ``; /*`/${process.env.REACT_APP_ENV}/${process.env.REACT_APP_SERVER}`;*/
export const env = ``; /*process.env.REACT_APP_ENV;*/
export const server = ``; /*process.env.REACT_APP_SERVER;*/

function App(props) {
  const theme = useTheme();

  // Detectar breakpoints usando `useMediaQuery` con los valores del tema MUI
  const issXs = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const isXs = useMediaQuery(theme.breakpoints.between("xs", "lg"));
  const isMd = useMediaQuery(theme.breakpoints.up("lg"));

  // Crear un objeto con los valores de los breakpoints detectados
  const breakpointValue = useMemo(
    () => ({
      isXs,
      isMd,
      issXs
    }),
    [isXs, isMd, issXs]
  );

  return (
    <BreakpointProvider value={breakpointValue}>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/login"}
            element={
              <NavbarLayout>
                <LoginView />
              </NavbarLayout>
            }
          />
          <Route
            path={"/login/:idStrategy"}
            element={
              <NavbarLayout>
                <LoginView />
              </NavbarLayout>
            }
          />
          <Route
            path={"/SignalRegister"}
            element={
              <NavbarLayout>
                <SignalRegisterView />
              </NavbarLayout>
            }
          />
          <Route
            path={"*"}
            element={
              <CookieChecker>
                <Routes>
                  <Route
                    path={"/"}
                    element={
                      <NavbarLayout>
                        <LoginView />
                      </NavbarLayout>
                    }
                  />
                  <Route
                    path={"/SignalProvider/Profile"}
                    element={
                      <NavbarAndSidebarSignal sidebarOption="SignalProfile">
                        <SignalProvider page="profile">Signal</SignalProvider>
                      </NavbarAndSidebarSignal>
                    }
                  />
                  <Route
                    path={"/SignalProvider/Followers"}
                    element={
                      <NavbarAndSidebarSignal sidebarOption="Followers">
                        <SignalProvider page="followers">
                          Follower
                        </SignalProvider>
                      </NavbarAndSidebarSignal>
                    }
                  />
                  <Route
                    path={"/SignalProvider/Orders"}
                    element={
                      <NavbarAndSidebarSignal sidebarOption="SignalOrders">
                        <SignalProvider page="orders">Orders</SignalProvider>
                      </NavbarAndSidebarSignal>
                    }
                  />
                  <Route
                    path={"/SignalProvider/StrategySettings"}
                    element={
                      <NavbarAndSidebarSignal sidebarOption="SignalProfile">
                        <SignalProvider page="updateStrategy">
                          Create strategy
                        </SignalProvider>
                      </NavbarAndSidebarSignal>
                    }
                  />
                  <Route
                    path={"/Follower/FollowingSettings"}
                    element={
                      <NavbarAndSidebarFollower sidebarOption="FollowingSettings">
                        <Follower page="followingSettings">
                          Following settings
                        </Follower>
                      </NavbarAndSidebarFollower>
                    }
                  />
                  <Route
                    path={"/Follower/Discover"}
                    element={
                      <NavbarAndSidebarFollower sidebarOption="Discover">
                        <Follower page={"discover"}>Discover</Follower>
                      </NavbarAndSidebarFollower>
                    }
                  />
                  <Route
                    path={"/Follower/Discover/Strategy/:idStrategy"}
                    element={
                      <NavbarAndSidebarFollower sidebarOption="Discover">
                        <Follower page={"discoverMore"}>Discover</Follower>
                      </NavbarAndSidebarFollower>
                    }
                  />
                  <Route
                    path={"/Follower/Orders"}
                    element={
                      <NavbarAndSidebarFollower sidebarOption="Orders">
                        <Follower page={"orders"}>Orders</Follower>
                      </NavbarAndSidebarFollower>
                    }
                  />
                  <Route exact path="*" element={<NotFound />} />
                </Routes>
              </CookieChecker>
            }
          />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </BreakpointProvider>
  );
}

export default App;
