//Follower settings component render when the user click the setting button in sidebarfollower component
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../../css/signal/signalProfile.scss";
import { useTranslation } from "react-i18next";
import userService from "../../../services/user.js";
import signalService from "../../../services/signalProvider.js";
import followerService from "../../../services/follower.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import profileImage from "../../../assets/SignalTestImage/profileImage.png";
import { InfoDetailBox } from "../../Generals/Cards/infoDetailBox.js";
import { InfoDetailLogoBox } from "../../Generals/Cards/InfoDetailLogoBox.js";
import InfoSettingsBox from "../../Generals/Cards/InfoSettingBox";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import * as masterData from "../../../Master Data/masterData.js";
import { Spaces, mainBox } from "../../../assets/Variables/SpaceVariables.js";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Paper,
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TableComponent from "../../Generals/Tables/TableComponent.js";
import TableToolTip from "../../Generals/Tooltips/TableToolTip.js";
import InfoChip from "../../Generals/Cards/InfoChip.js";
import IconComponent from "../../Generals/Icons/IconComponent.js";
import FollowingModal from "../../Generals/Modals/FollowingModal";
import NotificationModal from "../../Generals/Notifications/NotificationModal.js";
import SocialHub from "../../SignalProvider/ProfileScreen/SocialHub.js";
import {
  GetFollowingSettings,
  ClearFollowingSettings,
  SetUserInformation,
} from "../../../Tools/LocalStorage.js";
import StatisticalModule from "../../SignalProvider/ProfileScreen/StatisticalModule.js";
import Button3px from "../../Generals/Buttons/3pxButton";
import DateSelector from "../../Generals/Selectors/DateSelector";
import dayjs from "dayjs";
import { getLocalTimeZoneOffsetInSeconds } from "../../../Tools/DateTimeConvertions";
import Cookies from "universal-cookie";
import { useDynamicStyles } from "../../SignalProvider/ProfileScreen/Styles";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import StatusModule from "../../Generals/Cards/StatusModule";

function FollowedSignalDashboard(props) {
  //This function gets props from sidebarfollower component
  //#region "Instantiation of States"
  const { isXs, isMd } = useBreakpoint();
  const styles = useDynamicStyles();
  const [activeIndex, setActiveIndex] = useState(null);
  const [totalAmount, SetTotalAmount] = useState();
  const [page, setPage] = useState(1); // Estado para la página actual
  const [pageCount, setPageCount] = useState(0); // Estado para el número de páginas
  const [rows, setRows] = useState([]); // Estado para los datos de las filas
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [strategyInformation, setStrategyInformation] = useState({
    best_instrument_1: {
      name: "",
    },
    best_instrument_2: {
      name: "",
    },
    best_instrument_3: {
      name: "",
    },
  });
  const [strategyImage, setStrategyImage] = useState(profileImage);
  const [signalLogin, setSignalLogin] = useState(0);
  const [followingSettings, setFollowingSettings] = useState({});
  const [modalData, setModalData] = useState({});
  const [formValues, setFormValues] = useState({
    copy_mode: "Proportional",
    risk_ratio: 1,
    drawdown_mode: "NoDrawdown",
    max_drawdown: 0,
    min_lot_size: 0,
    max_lot_size: 0,
  });
  const [errors, setErrors] = useState({
    copy_mode: false,
    risk_ratio: false,
    drawdown_mode: false,
    max_drawdown: false,
    min_lot_size: false,
    max_lot_size: false,
  });
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
  //#endregion

  //#region "Declaration of Normal Variables or Constants"
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const formValuesRef = useRef(formValues);
  const cookies = new Cookies();
  const url = cookies.get("url");
  const columns = [
    {
      field: "ticket",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Ticket number">
          <Box sx={styles.tooltipBox}>
            {"Ticket "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "open_time",
      headerClassName: "header_table",
      renderHeader: () => <>{"Open time"}</>,
    },
    {
      field: "type",
      headerClassName: "header_table",
      renderHeader: () => <>{"Type"}</>,
    },
    {
      field: "symbol",
      headerClassName: "header_table",
      renderHeader: () => <>{"Symbol"}</>,
    },
    {
      field: "volume",
      headerClassName: "header_table",
      renderHeader: () => <>{"Volume"}</>,
    },
    {
      field: "open_price",
      headerClassName: "header_table",
      renderHeader: () => <>{"Price"}</>,
    },
    {
      field: "sp",
      headerClassName: "header_table",
      renderHeader: () => <>{"Stop loss"}</>,
    },
    {
      field: "tp",
      headerClassName: "header_table",
      renderHeader: () => <>{"Take profit"}</>,
    },
    {
      field: "close_time",
      headerClassName: "header_table",
      renderHeader: () => <>{"Close time"}</>,
    },
    {
      field: "close_price",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Close Price")}</>,
    },
    {
      field: "commission",
      headerClassName: "header_table",
      renderHeader: () => <>{"Commission"}</>,
    },
    {
      field: "swap",
      headerClassName: "header_table",
      renderHeader: () => <>{"Swap"}</>,
    },
    {
      field: "pnl",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Total profit">
          <Box sx={styles.tooltipBox}>
            {"Pnl "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "comment",
      headerClassName: "header_table",
      renderHeader: () => <>{"Comment"}</>,
    },
  ];
  //#endregion

  //#region "Handlers"
  const handleChangePage = (page) => {
    setPage(page);
    GetSignalClosedOrders(10, page, search, fromDate, toDate, signalLogin);
    RequestSettingsLogs(10, page);
  };

  const handleUnfollow = () => {
    setModalProps({
      open: true,
      iconType: "warning",
      title: "Are you sure you want to unfollow this strategy?",
      message:
        "You are about to stop following this trading strategy. If you decide to unfollow, you will no longer automatically copy the trades of the selected trader.",
      onAcceptLabel: "Continue",
      onRefuseLabel: "Cancel",
      onRefuse: () => handleCloseNotification(),
      onAccept: () => RequestUnfollow(),
    });
  };
  const handleSendCopyStatusEvent = (body) => {
    const event = new CustomEvent("copyStatus", {
      detail: { message: body },
    });

    // Emitir el evento
    window.dispatchEvent(event);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    // Actualiza los valores del formulario

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const currentFormValues = formValuesRef.current;
    let valid = true;
    let newErrors = {};
    // Verificar cada campo
    for (const [key, value] of Object.entries(currentFormValues)) {
      if (
        key === "min_lot_size" &&
        currentFormValues.max_lot_size !== 0 &&
        parseInt(value) >= currentFormValues.max_lot_size
      ) {
        newErrors[key] = true;
        valid = false;
      } else if (value === "" || value === false) {
        newErrors[key] = true;
        valid = false;
      } else {
        newErrors[key] = false;
      }
    }

    setErrors(newErrors);

    if (valid) {
      RequestEditFollowingSettings();
    } else {
    }
  };
  const handleEditFollowing = () => {
    setModalData({
      open: true,
      title: "Customize settings",
      onAcceptLabel: "Save",
      onRefuseLabel: "Cancel",
      modalType: "followCustom",
      onRefuse: () => handleModalClose(),
      onAccept: () => handleSubmit(),
    });
  };

  const handleModalClose = () => {
    setModalData({
      open: false,
    });
    setFormValues({
      copy_mode: followingSettings.copy_mode,
      risk_ratio: followingSettings.risk_ratio,
      drawdown_mode: followingSettings.drawdown_mode,
      max_drawdown: followingSettings.max_drawdown,
      min_lot_size: followingSettings.min_lot_size,
      max_lot_size: followingSettings.max_lot_size,
    });
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const handleToDate = (date) => {
    setToDate(date);
  };

  const handleSearch = () => {
    setPage(0);
    GetSignalClosedOrders(10, 0, search, fromDate, toDate, signalLogin);
  };
  //#endregion

  //#region "Requests"
  const RequestUnfollow = async () => {
    try {
      setLoading(true);
      const response = await followerService.stopFollowing();
      if (response.data != null) {
        setLoading(false);
        ClearFollowingSettings();
        handleSendCopyStatusEvent("");
        navigate("/Follower/Discover");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Error unfollowing strategy",
        message:
          "An unexpected error has occurred while the strategy was no longer followed, please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };
  const RequestDashboardData = async (signalLogin) => {
    try {
      setLoading(true);
      const response = await userService.getSignalInformation(signalLogin);
      if (response.data != null) {
        setLoading(false);
        setStrategyInformation(response.data.data);
        SetUserInformation(response);
        if (response.data.data.image.includes("/")) {
          setStrategyImage(`${url}/${response.data.data.image}`);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message:
          "Error loading strategy information, please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };

  const RequestSettingsLogs = async (quantity, page) => {
    try {
      setLoading(true);
      const response = await signalService.masterHistoricalSettings(
        quantity,
        page
      );
      if (response.data != null) {
        setLoading(false);
        let actualRows = response.data.data.data.map((log, index) => {
          let row = {
            id: index,
            disable_account_on_drawdown: log.disable_account_on_drawdown,
            drawdown_mode: log.drawdown_mode,
            fee_value: log.fee_value,
            max_drowdown: log.max_drowdown,
            message: log.message,
            strategy_name: log.strategy_name,
            strategy_type: log.strategy_type,
            time: log.time,
            visibility: log.visibility,
            ip: log.ip,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message: "Error loading settings logs, please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };
  const GetSignalClosedOrders = async (
    quantityPerPage,
    page,
    search,
    from,
    to,
    signalLogin
  ) => {
    try {
      setLoading(true);
      const dateFromSeconds = from / 1000 + getLocalTimeZoneOffsetInSeconds();
      const dateToSeconds =
        to / 1000 + getLocalTimeZoneOffsetInSeconds() + 86400;
      const response = await followerService.LoadSignalClosedOrders(
        quantityPerPage,
        page,
        search,
        dateFromSeconds,
        dateToSeconds,
        signalLogin
      );

      if (response.status === 200) {
        setLoading(false); //Stops loading spinning
        let actualRows = response.data.data.data.map((log, index) => {
          const dateOpen = new Date(log.open_time * 1000); // Convertimos segundos a milisegundos
          const yearOpen = dateOpen.getUTCFullYear();
          const monthOpen = String(dateOpen.getUTCMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
          const dayOpen = String(dateOpen.getUTCDate()).padStart(2, "0");
          const hoursOpen = String(dateOpen.getUTCHours()).padStart(2, "0");
          const minutesOpen = String(dateOpen.getUTCMinutes()).padStart(2, "0");
          const secondsFormattedOpen = String(
            dateOpen.getUTCSeconds()
          ).padStart(2, "0");
          const formattedDateOpen = `${yearOpen}-${monthOpen}-${dayOpen} ${hoursOpen}:${minutesOpen}:${secondsFormattedOpen}`;

          const dateClose = new Date(log.close_time * 1000); // Convertimos segundos a milisegundos
          const yearClose = dateClose.getUTCFullYear();
          const monthClose = String(dateClose.getUTCMonth() + 1).padStart(
            2,
            "0"
          ); // Los meses van de 0 a 11
          const dayClose = String(dateClose.getUTCDate()).padStart(2, "0");
          const hoursClose = String(dateClose.getUTCHours()).padStart(2, "0");
          const minutesClose = String(dateClose.getUTCMinutes()).padStart(
            2,
            "0"
          );
          const secondsFormattedClose = String(
            dateClose.getUTCSeconds()
          ).padStart(2, "0");
          const formattedDateClose = `${yearClose}-${monthClose}-${dayClose} ${hoursClose}:${minutesClose}:${secondsFormattedClose}`;

          let row = {
            id: index,
            ticket: log.ticket,
            open_time: formattedDateOpen,
            type: log.type,
            symbol: log.symbol,
            volume: log.volume,
            open_price: log.open_price,
            sl: log.sl,
            tp: log.tp,
            close_time: formattedDateClose,
            close_price: log.close_price,
            commission: log.commission,
            swap: log.swap,
            pnl: log.pnl,
            comment: log.comment,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      } else {
        setLoading(false); //Stops loading spinning
      }
      return response.data;
    } catch (error) {
      if (error.response.data.message === "Login is required") {
        setLoading(false); //Stops loading spinning
        setModalProps({
          open: true,
          iconType: "error",
          title: "Session Expired",
          message:
            "Your session has expired, please log in again and try again.",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
        navigate("/");
      } else {
        setLoading(false); //Stops loading spinning
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message:
            "An unexpected error has occurred getting closed orders, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    }
  };

  const RequestEditFollowingSettings = async () => {
    try {
      setLoading(true);
      const currentFormValues = formValuesRef.current;
      const response = await followerService.editFollower(currentFormValues);
      if (response.data != null) {
        setLoading(false);
        setFollowingSettings((prevSettings) => ({
          ...prevSettings, // Mantiene las propiedades anteriores de followingSettings
          copy_mode: currentFormValues.copy_mode,
          risk_ratio: currentFormValues.risk_ratio,
          drawdown_mode: currentFormValues.drawdown_mode,
          max_drawdown: currentFormValues.max_drawdown,
          min_lot_size: currentFormValues.min_lot_size,
          max_lot_size: currentFormValues.max_lot_size,
        }));
        setModalData({
          open: false,
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message:
          "Error updating follower settings, please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };

  const RequestStrategyImage = async (imageUrl) => {
    try {
      const response = await userService.getStrategyImage(imageUrl);
      if (response.data != null) {
        setStrategyImage(response.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region "useEffects"
  useEffect(() => {
    setFromDate(dayjs().startOf("day"));
    setToDate(dayjs().add(1, "day").startOf("day"));
    const infoFollowing = GetFollowingSettings();
    if (infoFollowing.dataExits) {
      RequestDashboardData(infoFollowing.data.signal_followed);
      setSignalLogin(infoFollowing.data.signal_followed);
      setFollowingSettings(infoFollowing.data);
      setFormValues({
        copy_mode: infoFollowing.data.copy_mode,
        risk_ratio: infoFollowing.data.risk_ratio,
        drawdown_mode: infoFollowing.data.drawdown_mode,
        max_drawdown: infoFollowing.data.max_drawdown,
        min_lot_size: infoFollowing.data.min_lot_size,
        max_lot_size: infoFollowing.data.max_lot_size,
      });
    } else {
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message: "You are not following any strategy, please reload",
        onAcceptLabel: "Continue",
        onRefuseLabel: "",
        onRefuse: () => undefined,
        onAccept: () => navigate("Follower/Discover"),
      });
    }
    GetSignalClosedOrders(
      10,
      0,
      search,
      dayjs().startOf("day"),
      dayjs().add(1, "day").startOf("day"),
      infoFollowing.data.signal_followed
    );
  }, []);

  useEffect(() => {
    formValuesRef.current = formValues;
  }, [formValues]);
  //#endregion

  //#region "HTML"
  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <FollowingModal
        modalData={modalData}
        formValues={formValues}
        errors={errors}
        handleModalClose={handleModalClose}
        handleChange={handleChange}
      />
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
        onRefuseLabel={modalProps.onRefuseLabel}
        onRefuse={modalProps.onRefuse}
      />

      <Box sx={styles.form}>
      {isXs && <StatusModule></StatusModule>}
        <Box sx={styles.profileSocialContainer}>
          <Box sx={styles.profileContainer}>
            <Box sx={styles.profileTitleBox}>
              <Box sx={styles.avatarTitleBox}>
                <Box>
                  <Avatar
                    alt="strategy image"
                    src={strategyImage}
                    sx={{ width: 95, height: 95 }}
                  ></Avatar>
                </Box>
                <Box sx={styles.descriptionBox}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_3_Bold,
                    }}
                  >
                    {strategyInformation.strategy_name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                    }}
                  >
                    {strategyInformation.description}
                  </Typography>
                  <Button onClick={handleUnfollow} sx={styles.shareLinkButton}>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1_Bold,
                        lineHeight: 1,
                      }}
                    >
                      Unfollow
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.strategyOverviewBox}>
              <Box sx={styles.StrategyOverviewTitleBox}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_4_Bold,
                    lineHeight: 1,
                  }}
                >
                  Strategy overview
                </Typography>
                <Box sx={styles.chipsContainer}>
                  <InfoChip
                    text={`Currency: ${strategyInformation.currency}`}
                  />
                  <InfoChip
                    text={`Strategy type: ${strategyInformation.strategy_type}`}
                  />
                </Box>
              </Box>
              <Grid container spacing={2} sx={{height: "auto"}}>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Leverage"}
                    detail={strategyInformation.leverage}
                    logo={"page_info"}
                  ></InfoDetailBox>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Account type"}
                    detail={strategyInformation.account_type}
                    logo={"assignment_ind"}
                  ></InfoDetailBox>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Drawdown"}
                    detail={strategyInformation.max_drawdown}
                    logo={"swap_vert"}
                  ></InfoDetailBox>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                {(strategyInformation.fee_value !== strategyInformation.fee_new_value) ?
                    <InfoDetailBox
                      title={"Fee"}
                      detail={`${strategyInformation.fee_value}/${strategyInformation.fee_new_value} %`}
                      logo={"sell"}
                    ></InfoDetailBox>
                    :
                    <InfoDetailBox
                      title={"Fee"}
                      detail={`${strategyInformation.fee_value}%`}
                      logo={"sell"}
                    ></InfoDetailBox>
                  }
                </Grid>
              </Grid>
            </Box>
            {(strategyInformation.fee_value !== strategyInformation.fee_new_value) ? 
              <Box sx={{
                display:'flex',
                justifyContent:'center',
                color:'#dc3545',
                fontWeight:'600'
              }}>
                {"Important!, this signal provider has just updated its fee from " + strategyInformation.fee_value + "% to " + strategyInformation.fee_new_value + "%. This new value will be applied once the current month's performance is paid"}
              </Box>
              : null
            }
            <Box sx={styles.strategyOverviewBox}>
              <Box sx={styles.StrategyOverviewTitleBox}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_4_Bold,
                    lineHeight: 1,
                  }}
                >
                  Most traded symbols
                </Typography>
              </Box>
              <Grid container spacing={2} sx={{height: "auto"}}>
                <Grid item xs={12} sm={4} md={4}>
                  <InfoDetailLogoBox
                    title={strategyInformation.best_instrument_1.name}
                    detail={`${strategyInformation.best_instrument_1.percentage}%`}
                    asset1={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_1.name.slice(0, 3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                    asset2={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_1.name.slice(3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                  ></InfoDetailLogoBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <InfoDetailLogoBox
                    title={strategyInformation.best_instrument_2.name}
                    detail={`${strategyInformation.best_instrument_2.percentage}%`}
                    asset1={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_2.name.slice(0, 3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                    asset2={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_2.name.slice(3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                  ></InfoDetailLogoBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <InfoDetailLogoBox
                    title={strategyInformation.best_instrument_3.name}
                    detail={`${strategyInformation.best_instrument_3.percentage}%`}
                    asset1={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_3.name.slice(0, 3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                    asset2={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_3.name.slice(3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                  ></InfoDetailLogoBox>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <SocialHub
            signalInformation={strategyInformation}
            signalImage={strategyImage}
            signalAccount={signalLogin}
          ></SocialHub>
        </Box>
        <Paper sx={styles.settingsFollowingContainer}>
          <Box sx={styles.settingsFollowingBox}>
            <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
              My following settings
            </Typography>
            <Box>
              <Button
                onClick={handleEditFollowing}
                sx={{
                  display: "inline-block",
                  color: "black",
                  padding: "0px",
                  minWidth: "auto",
                }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: Spaces.Size_6, lineHeight: "1" }}
                >
                  edit
                </span>
              </Button>
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={3} md={3}>
              <InfoSettingsBox
                title={"Drawdown"}
                detail={
                  followingSettings.drawdown_mode === "InEquity"
                    ? "In equity"
                    : "No drawdown"
                }
              ></InfoSettingsBox>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <InfoSettingsBox
                title={"Copy mode"}
                detail={followingSettings.copy_mode}
              ></InfoSettingsBox>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <InfoSettingsBox
                title={"Copy status"}
                detail={
                  followingSettings.connect === true ? "Coping" : "No coping"
                }
              ></InfoSettingsBox>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <InfoSettingsBox
                title={"Risk ratio"}
                detail={followingSettings.risk_ratio}
              ></InfoSettingsBox>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <InfoSettingsBox
                title={"Min. lot size"}
                detail={`${followingSettings.min_lot_size}`}
              ></InfoSettingsBox>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <InfoSettingsBox
                title={"Max. lot size"}
                detail={`${followingSettings.max_lot_size}`}
              ></InfoSettingsBox>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <InfoSettingsBox
                title={"Max. DD"}
                detail={`${followingSettings.max_drawdown}`}
              ></InfoSettingsBox>
            </Grid>
          </Grid>
        </Paper>
        <StatisticalModule
          strategyInfo={strategyInformation}
        ></StatisticalModule>
        <Paper sx={styles.settingsLogsContainer}>
          <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
            Close orders
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMd ? "row" : "column",
              alignItems: isMd ? "flex-end" : "flex-start",
              justifyContent: "flex-start",
              gap: Spaces.Size_5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: Spaces.Size_5,
              }}
            >
              <DateSelector
                title={t("follower.From")}
                value={fromDate}
                handleChange={handleFromDate}
              ></DateSelector>
              <DateSelector
                title={t("follower.To")}
                value={toDate}
                handleChange={handleToDate}
              ></DateSelector>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: Spaces.Size_5,
                width: "100%",
              }}
            >
              <TextField
                value={search}
                onChange={handleSearchChange}
                placeholder={t("follower.Search order")}
                sx={{
                  width: isMd ? "263px" : "100%",
                  maxHeight: "38px",
                  maxWidth: "400px",
                  padding: "0px",
                  fontFamily: TextVariables.Overline,
                  border: `1px solid ${Colors.Secondary_400}`,
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& .MuiInputBase-input": {
                      padding: Spaces.Size_3,
                      fontFamily: TextVariables.Overline,
                      color: Colors.Secondary_900,
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        marginRight: "0px",
                      }}
                    >
                      <SearchIcon padding={0} />
                    </InputAdornment>
                  ),
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Elimina el borde
                    },
                  },
                }}
              />
              <Button3px
                label={"Search"}
                color={Colors.Secondary_700}
                backgroundColor={Colors.Gray_200}
                HandleOnClick={handleSearch}
              ></Button3px>
            </Box>
          </Box>
          <TableComponent
            rows={rows}
            page={page}
            totalAmount={totalAmount}
            pageCount={pageCount}
            handleChangePage={handleChangePage}
            columns={columns}
          />
        </Paper>
      </Box>
    </Box>
  );
  //#endregion
}

export default FollowedSignalDashboard;
