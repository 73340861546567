//Follower settings component render when the user click the setting button in sidebarfollower component
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../../assets/logos/image 3.svg";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import { Box, Typography } from "@mui/material";
import { GetUserInformation } from "../../../Tools/LocalStorage.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import SlimSelector from "../../Generals/Selectors/SlimSelector";
import LinesChart from "../../Generals/Charts/LinealChart.js";
import HalfGaugeChart from "../../Generals/Charts/GaugeChart.js";
import CircularChart from "../../Generals/Charts/CircularChart";
import * as masterData from "../../../Master Data/masterData.js";
import Switch from "../../Generals/Buttons/Switch";
import CustomLegend from "../../Generals/Inputs/CustomLegend";
import signalService from "../../../services/signalProvider";
import NotificationModal from "../../Generals/Notifications/NotificationModal";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader";
import {
  getCurrentMonthNumber,
  getCurrentYearString,
} from "../../../Tools/DateTimeConvertions";
import { useDynamicStyles } from "./Styles";
import { useBreakpoint } from "../../../config/BreakpointContext.js";

function StatisticalModule({ strategyInfo }) {
  const { isXs, isMd } = useBreakpoint();
  //This function gets props from sidebarfollower component
  //#region "Instantiation of States"
  const styles = useDynamicStyles();
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [strategyInformation, setStrategyInformation] = useState(false);
  const [selectedSwitch, setSelectedSwitch] = useState("performance");
  const [value, setValue] = useState(0);
  const [selectedFrequency, setSelectedFrequency] = useState("Annual");
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthNumber());
  const [selectedYear, setSelectedYear] = useState(getCurrentYearString());
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onAccept: undefined,
  });
  const [tradesData, setTradesData] = useState({
    totalTrades: 0,
    winPercentage: 0,
  });
  const [chartData, setChartData] = React.useState({
    labels: ["1"],
    datasets: [
      {
        label: "Profit",
        data: [23],
        borderColor: Colors.Secondary_600,
        backgroundColor: "rgb(54, 162, 235, 0.2)",
        yAxisID: "y-axis-volumen",
        tension: 0.4,
      },
    ],
  });
  //#endregion

  //#region "Declaration of Normal Variables or Constants"

  //#endregion

  //#region "Handlers"
  const handleSwitch = (name) => {
    setSelectedSwitch(name);
    if (name === "performance") {
      handleFrecuencySelector("Annual", name);
    } else if (name === "followers") {
      handleFrecuencySelector(selectedFrequency, name);
    }
  };

  const handleFrecuencySelector = (event, type = selectedSwitch) => {
    console.log(event);
    setSelectedFrequency(event);
    if (event === "Annual") {
      RequestPerformanceHistory(
        strategyInfo.signal_login,
        type,
        event,
        selectedYear
      );
    } else {
      RequestPerformanceHistory(
        strategyInfo.signal_login,
        type,
        event,
        selectedYear,
        selectedMonth
      );
    }
  };

  const handleYearSelector = (event) => {
    setSelectedYear(event.target.value);
    if (selectedSwitch === "performance" || selectedFrequency === "Annual") {
      RequestPerformanceHistory(
        strategyInfo.signal_login,
        selectedSwitch,
        selectedFrequency,
        event.target.value
      );
    } else if (selectedFrequency === "Monthly") {
      RequestPerformanceHistory(
        strategyInfo.signal_login,
        selectedSwitch,
        selectedFrequency,
        event.target.value,
        selectedMonth
      );
    }
  };

  const handleMonthSelector = (event) => {
    setSelectedMonth(event.target.value);
    RequestPerformanceHistory(
      strategyInfo.signal_login,
      selectedSwitch,
      selectedFrequency,
      selectedYear,
      event.target.value
    );
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };
  //#endregion

  //#region "Requests"
  const GetLocalStorageData = () => {
    let dataResponse = GetUserInformation();
    if (dataResponse.dataExits) {
      setStrategyInformation(dataResponse.data);
      return true;
    } else {
      console.log("No local data");
      return false;
    }
  };

  const RequestPerformanceHistory = async (
    signal,
    type,
    frecuency,
    year,
    month = ""
  ) => {
    try {
      setLoading(true);
      const response = await signalService.getSignalPerformance(
        signal,
        year,
        month
      );
      if (response.data != null) {
        setLoading(false);
        let labels = [];
        let data = [];

        if (frecuency === "Annual") {
          labels = response.data.data.data.map(
            (item) => masterData.monthsSimply[item.x_axis - 1]
          );
        } else {
          labels = response.data.data.data.map((item) => item.x_axis);
        }

        if (type === "performance") {
          data = response.data.data.data.map((item) =>
            item.performance === -1 ? 0 : item.performance
          );
        } else if (type === "followers") {
          data = response.data.data.data.map((item) =>
            item.followers === -1 ? 0 : item.followers
          );
        }

        setChartData({
          labels: labels,
          datasets: [
            {
              label: type === "performance" ? "Profit" : "Followers",
              data: data,
              borderColor:
                selectedFrequency === "Annual"
                  ? Colors.Error_400
                  : Colors.Secondary_600,
              backgroundColor: "rgb(54, 162, 235, 0.2)",
              yAxisID: "y-axis-volumen",
              tension: 0.4,
            },
          ],
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message:
          "An error has occurred while loading performance information. Please try reloading, if the problem persists please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };
  //#endregion

  //#region "useEffects"
  useEffect(() => {
    if (strategyInfo.signal_login !== undefined) {
      handleSwitch("performance");
    }
  }, [strategyInfo.signal_login]);
  //#endregion

  //#region "HTML"
  return (
    <Box sx={styles.statisticalTradesContainer}>
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
      />
      <Box sx={styles.statisticalContainer}>
        <Box sx={styles.statisticalNavbarBox}>
          <Switch
            label={t("Performance")}
            name={"performance"}
            selector={selectedSwitch}
            HandleOnClick={(e) => handleSwitch("performance")}
          ></Switch>
          <Switch
            label={t("Risk score")}
            name={"riskScore"}
            selector={selectedSwitch}
            HandleOnClick={(e) => handleSwitch("riskScore")}
          >
            {t("Risk score")}
          </Switch>
          <Switch
            label={t("Followers")}
            name={"followers"}
            selector={selectedSwitch}
            HandleOnClick={(e) => handleSwitch("followers")}
          >
            {t("Followers")}
          </Switch>
        </Box>
        {selectedSwitch === "performance" &&
          chartData.datasets[0].data.length > 0 && (
            <Box sx={styles.performanceBox}>
              {isMd && (              <Box sx={styles.statisticalLegendsBox}>
                <Box sx={styles.statisticalFiltersBox}>
                  <CustomLegend
                    name={t("Annual")}
                    value="50"
                    onCheck={(e) => handleFrecuencySelector("Annual")}
                    checked={selectedFrequency === "Annual" ? true : false}
                    lineColor={Colors.Error_400}
                  ></CustomLegend>
                  {selectedSwitch !== "performance" && (
                    <CustomLegend
                      name={t("Monthly")}
                      value="50"
                      onCheck={(e) => handleFrecuencySelector("Monthly")}
                      checked={selectedFrequency === "Monthly" ? true : false}
                      lineColor={Colors.Secondary_600}
                    ></CustomLegend>
                  )}
                </Box>
                <Box sx={styles.statisticalFiltersBox}>
                  <SlimSelector
                    title={t("Year")}
                    value={selectedYear}
                    handleChange={handleYearSelector}
                    options={masterData.years}
                  />
                  {selectedFrequency === "Monthly" &&
                    selectedSwitch !== "performance" && (
                      <SlimSelector
                        title={t("Month")}
                        value={selectedMonth}
                        handleChange={handleMonthSelector}
                        options={masterData.months}
                      />
                    )}
                </Box>
              </Box>)}
              <LinesChart dataChart={chartData} yLabel="Profit" />
              {isXs && (              <Box sx={styles.statisticalLegendsBox}>
                <Box sx={styles.statisticalFiltersBox}>
                  <CustomLegend
                    name={t("Annual")}
                    value="50"
                    onCheck={(e) => handleFrecuencySelector("Annual")}
                    checked={selectedFrequency === "Annual" ? true : false}
                    lineColor={Colors.Error_400}
                  ></CustomLegend>
                  {selectedSwitch !== "performance" && (
                    <CustomLegend
                      name={t("Monthly")}
                      value="50"
                      onCheck={(e) => handleFrecuencySelector("Monthly")}
                      checked={selectedFrequency === "Monthly" ? true : false}
                      lineColor={Colors.Secondary_600}
                    ></CustomLegend>
                  )}
                </Box>
                <Box sx={styles.statisticalFiltersBox}>
                  <SlimSelector
                    title={t("Year")}
                    value={selectedYear}
                    handleChange={handleYearSelector}
                    options={masterData.years}
                  />
                  {selectedFrequency === "Monthly" &&
                    selectedSwitch !== "performance" && (
                      <SlimSelector
                        title={t("Month")}
                        value={selectedMonth}
                        handleChange={handleMonthSelector}
                        options={masterData.months}
                      />
                    )}
                </Box>
              </Box>)}
            </Box>
          )}
        {selectedSwitch === "performance" &&
          chartData.datasets[0].data.length <= 0 && (
            <Box sx={styles.performanceBox}>
              <img
                alt="background empty"
                src={backgroundImage}
                style={{ mixBlendMode: "luminosity", width: 250, opacity: 0.8 }}
              />
              <Typography sx={{ fontFamily: TextVariables.Body_4 }}>
                {t("Your account has no performance data recorded yet")}
              </Typography>
            </Box>
          )}
        {selectedSwitch === "riskScore" && (
          <Box
            sx={{
              width: "100%",
              height: "95%",
              padding: "97px 0px 100px 0px",
            }}
          >
            <HalfGaugeChart
              riskValue={strategyInfo.risk_score}
            ></HalfGaugeChart>
          </Box>
        )}
        {selectedSwitch === "followers" &&
          chartData.datasets[0].data.length > 0 && (
            <Box sx={styles.performanceBox}>
              {isMd && (
                <Box sx={styles.statisticalLegendsBox}>
                  <Box sx={styles.statisticalFiltersBox}>
                    <CustomLegend
                      name={t("Annual")}
                      value="50"
                      onCheck={(e) => handleFrecuencySelector("Annual")}
                      checked={selectedFrequency === "Annual" ? true : false}
                      lineColor={Colors.Error_400}
                    ></CustomLegend>
                    <CustomLegend
                      name={t("Monthly")}
                      value="50"
                      onCheck={(e) => handleFrecuencySelector("Monthly")}
                      checked={selectedFrequency === "Monthly" ? true : false}
                      lineColor={Colors.Secondary_600}
                    ></CustomLegend>
                  </Box>
                  <Box sx={styles.statisticalFrecuencyContainer}>
                    <SlimSelector
                      title={t("Year")}
                      value={selectedYear}
                      handleChange={handleYearSelector}
                      options={masterData.years}
                    />
                    {selectedFrequency === "Monthly" && (
                      <SlimSelector
                        title={t("Month")}
                        value={selectedMonth}
                        handleChange={handleMonthSelector}
                        options={masterData.months}
                      />
                    )}
                  </Box>
                </Box>
              )}

              <LinesChart dataChart={chartData} yLabel="Followers" />
              {isXs && (
                <Box sx={styles.statisticalLegendsBox}>
                  <Box sx={styles.statisticalFiltersBox}>
                    <CustomLegend
                      name={t("Annual")}
                      value="50"
                      onCheck={(e) => handleFrecuencySelector("Annual")}
                      checked={selectedFrequency === "Annual" ? true : false}
                      lineColor={Colors.Error_400}
                    ></CustomLegend>
                    <CustomLegend
                      name={t("Monthly")}
                      value="50"
                      onCheck={(e) => handleFrecuencySelector("Monthly")}
                      checked={selectedFrequency === "Monthly" ? true : false}
                      lineColor={Colors.Secondary_600}
                    ></CustomLegend>
                  </Box>
                  <Box sx={styles.statisticalFrecuencyContainer}>
                    <SlimSelector
                      title={t("Year")}
                      value={selectedYear}
                      handleChange={handleYearSelector}
                      options={masterData.years}
                    />
                    {selectedFrequency === "Monthly" && (
                      <SlimSelector
                        title={t("Month")}
                        value={selectedMonth}
                        handleChange={handleMonthSelector}
                        options={masterData.months}
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        {selectedSwitch === "followers" &&
          chartData.datasets[0].data.length <= 0 && (
            <Box sx={styles.performanceBox}>
              <img
                alt="background empty"
                src={backgroundImage}
                style={{ mixBlendMode: "luminosity", width: 250, opacity: 0.8 }}
              />
              <Typography sx={{ fontFamily: TextVariables.Body_4 }}>
                {t("Your account has no historical data recorded yet")}
              </Typography>
            </Box>
          )}
      </Box>
      <Box sx={styles.totalTradesContainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: Spaces.Size_3,
            alignItems: "center",
          }}
        >
          {strategyInfo.lost_trades + strategyInfo.profited_trades === 0 && (
            <span
              className="material-symbols-outlined"
              style={{ fontSize: Spaces.Size_10, lineHeight: 1 }}
            >
              {t("trending_up")}
            </span>
          )}
          {strategyInfo.lost_trades + strategyInfo.profited_trades > 0 && (
            <CircularChart
              value={Math.round(
                (strategyInfo.profited_trades /
                  (strategyInfo.lost_trades + strategyInfo.profited_trades)) *
                  100,
                2
              )}
              maxValue={100}
              totalAmount={
                strategyInfo.lost_trades + strategyInfo.profited_trades
              }
            />
          )}

          <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
            {t("Trades")}
          </Typography>
          {strategyInfo.lost_trades + strategyInfo.profited_trades === 0 ? (
            <Typography sx={{ fontFamily: TextVariables.Link_5 }}>
              {t("No results yet")}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: isMd ? Spaces.Size_5 : Spaces.Size_1,
            position: "absolute",
            bottom: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-around",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    width: Spaces.Size_4,
                    height: Spaces.Size_1,
                    backgroundColor: Colors.Error_500,
                    display: "inline-block",
                    borderRadius: Spaces.Size_1,
                  }}
                />
              </Box>
              <Typography sx={{ fontFamily: TextVariables.Link_5 }}>
                {t("Profited trades")}
              </Typography>
            </Box>
            <Typography sx={{ fontFamily: TextVariables.Caption_bold_1 }}>
              {strategyInfo.lost_trades + strategyInfo.profited_trades > 0
                ? Math.round(
                    (strategyInfo.profited_trades /
                      (strategyInfo.lost_trades +
                        strategyInfo.profited_trades)) *
                      100,
                    2
                  )
                : 0}
              %
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-around",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    width: Spaces.Size_4,
                    height: Spaces.Size_1,
                    backgroundColor: Colors.Secondary_900,
                    display: "inline-block",
                    borderRadius: Spaces.Size_1,
                  }}
                />
              </Box>
              <Typography sx={{ fontFamily: TextVariables.Link_5 }}>
                {t("Lost trades")}
              </Typography>
            </Box>
            <Typography sx={{ fontFamily: TextVariables.Caption_bold_1 }}>
              {strategyInfo.lost_trades + strategyInfo.profited_trades > 0
                ? Math.round(
                    (strategyInfo.lost_trades /
                      (strategyInfo.lost_trades +
                        strategyInfo.profited_trades)) *
                      100,
                    2
                  )
                : 0}
              %
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
  //#endregion
}

export default StatisticalModule;
