//Singleton script tha contains all the request uses in login component
import axios from "axios";
import Cookies from "universal-cookie";

//Main path builds depending on the enviroment selected or run by administrator
const cookies = new Cookies();
const url = cookies.get("url");

let baseUrl = `${url}/api`;

function manejarCookieActualizada(event) {
  baseUrl = `${event.detail.value}/api`;
}

// Agregar un listener para el evento de cookieActualizada
window.addEventListener("updatedUrl", manejarCookieActualizada);

//Request that bring the list of Signal providers available
const login = async (credentials) => {
  const res = await axios.post(`${baseUrl}/login`, credentials);
  return res;
};

//Request that convert a follower into a Signal provider
const createMaster = async (credentials) => {
  console.log(credentials);
  const res = await axios.post(`${baseUrl}/become-signal`, credentials);
  return res;
};

const methods = {
  login,
  createMaster,
};

export default methods;
