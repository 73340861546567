import { Button } from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import {Spaces} from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";

function GreyButton({ children, HandleOnClick }) {
  return (
    <Button
      onClick={HandleOnClick}
      sx={{
        backgroundColor: Colors.Gray_200,
        fontFamily: TextVariables.Body_4,
        color: Colors.Gray_700,
        textTransform: "none",
        padding: `${Spaces.Size_4}`,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: Spaces.Size_2,
        lineHeight: "1",
        "&:hover": {
          backgroundColor: "#FF0000", // Cambia el color al hacer hover
          opacity: 0.8, // Aplica opacidad al hacer hover
        },
      }}
    >
      {children}
    </Button>
  );
}

export default GreyButton;
