import React, { useState } from "react";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import whatsappLogo from "../../../assets/logos/SocialMedia/whatsapp.svg";
import InfoChip from "../../Generals/Cards/InfoChip";

function ShareLinkModal({ open, onClose, handleCopy, title = "" }) {
  const [copied, setCopied] = useState(false);

  const copyHandle = () => {
    setCopied(true); // Actualiza el estado a "copiado"
    setTimeout(() => setCopied(false), 2000); // Desaparece el letrero después de 2 segundos
    handleCopy();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: Spaces.Size_10,
          alignItems: "center",
          gap: Spaces.Size_9,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "415px",
          borderRadius: Spaces.Size_4,
          background: "white",
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            display: "inline-block",
            position: "absolute",
            top: "20px",
            right: "24px",
            padding: "0px",
            minWidth: "auto",
          }}
        >
          <span
            style={{ color: Colors.Secondary_900, fontSize: Spaces.Size_6 }}
            className="material-symbols-outlined"
          >
            close
          </span>
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: Spaces.Size_5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: Spaces.Size_10,
              height: Spaces.Size_10,
              padding: Spaces.Size_2,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: Spaces.Size_8,
            }}
          >
            <span
              style={{ color: Colors.Info_800, fontSize: Spaces.Size_10 }}
              className="material-symbols-outlined"
            >
              info
            </span>
          </Box>

          <Typography
            id="notification-modal-title"
            sx={{ fontFamily: TextVariables.Subtitle_4, lineHeight: "1", textAlign: "center" }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: Spaces.Size_5,
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              padding: `${Spaces.Size_3} ${Spaces.Size_5}`,
              gap: Spaces.Size_3,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={copyHandle}
              sx={{
                display: "flex",
                width: Spaces.Size_12,
                height: Spaces.Size_12,
                padding: Spaces.Size_5,
                justifyContent: "center",
                alignItems: "center",
                gap: Spaces.Size_2,
                borderRadius: Spaces.Size_10,
                background: Colors.Secondary_50,
              }}
            >
              <span
                style={{ color: Colors.Secondary_900, fontSize: Spaces.Size_6 }}
                className="material-symbols-outlined"
              >
                content_copy
              </span>
            </IconButton>
            <Typography sx={{ fontFamily: TextVariables.Body_4 }}>
              Copy link
            </Typography>
            {copied && (
              <Box sx={{ position: "absolute", top: 100 }}>
                <InfoChip text={"Copied"} />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: `${Spaces.Size_3} ${Spaces.Size_5}`,
              gap: Spaces.Size_3,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={handleCopy}
              component="a"
              href="https://web.whatsapp.com/"
              target="_blank" // Abre en una nueva pestaña
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                width: Spaces.Size_12,
                height: Spaces.Size_12,
                padding: Spaces.Size_5,
                justifyContent: "center",
                alignItems: "center",
                gap: Spaces.Size_2,
                borderRadius: Spaces.Size_10,
                background: Colors.Secondary_50,
              }}
            >
              <img
                style={{ height: Spaces.Size_6, width: Spaces.Size_6 }}
                alt="whatsapp"
                src={whatsappLogo}
              ></img>
            </IconButton>
            <Typography sx={{ fontFamily: TextVariables.Body_4 }}>
              WhatsApp
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default ShareLinkModal;
