//Singleton script tha contains all the request uses in Signal provider components
import axios from "axios";
import Cookies from "universal-cookie";

//Main path builds depending on the enviroment selected or run by administrator
const cookies = new Cookies();
const url = cookies.get("url");

let token = cookies.get("token");

let headers = {
  //Headers preparation, the token is get from cookies, the same token obtained when the user log in
  "Content-type": "application/json; charset=UTF-8",
  Authorization: "Bearer " + token,
};
let baseUrl = `${url}/api`;

function manejarCookieActualizada(event) {
  baseUrl = `${event.detail.value}/api`;
}

function onUpdateToken(event) {
  token = event.detail.value;
  headers.Authorization = "Bearer " + token;
}


// Agregar un listener para el evento de cookieActualizada
window.addEventListener("updatedUrl", manejarCookieActualizada);
window.addEventListener("tokenUpdatedEvent", onUpdateToken);

//Request that bring the list followers than is following the signal provider
const getSlaves = async (quantity, page, search) => {
  const res = await axios.get(
    `${baseUrl}/follower-list?quantity=${quantity}&page=${page}&search=${search}`,
    { headers }
  );
  return res;
};

//Request that delete a Signal provider account
const deleteMaster = async () => {
  const res = await axios.delete(`${baseUrl}/become-signal`, { headers });
  return res;
};

//Request that reactivate a disable Signal provider account
const reactivateAccount = async () => {
  const res = await axios.get(`${baseUrl}/reactivate`, { headers });
  return res;
};

//Request that update the information of a Signal provider account
const editMaster = async (params) => {
  const res = await axios.put(`${baseUrl}/information/signal`, params, {
    headers,
  });
  return res;
};

//Request that bring the information about a follower that is following the Signal provider account
const getFollowerInformation = async (params) => {
  const res = await axios.post(`${baseUrl}/information`, params, { headers });
  return res;
};

//It's not being used in this moment
const makeVisible = async (params) => {
  const res = await axios.post(`${baseUrl}/discoverable`, params);
  return res;
};

//It's not being used in this moment
const makeInvisible = async (params) => {
  const res = await axios.post(`${baseUrl}/undiscoverable`, params);
  return res;
};

//Request that bring the list of historical settings made by signal provider user
const masterHistoricalSettings = async (quantity, page) => {
  const res = await axios.get(
    `${baseUrl}/historical?quantity=${quantity}&page=${page}`,
    { headers }
  );
  return res;
};

//Request function to get the list of Signal open orders from de communitraders server

const LoadSignalOpenOrders = async (quantity, page) => {
  try {
    const res = await axios.get(
      `${baseUrl}/open_trades_history?quantity=${quantity}&page=${page}`,
      { headers: headers }
    );
    return res;
  } catch (error) {
    throw error; // Asegúrate de lanzar el error para manejarlo en el lugar donde se llama a la función
  }
};

//Request function to get the list of Signal open orders from de communitraders server

const LoadSignalClosedOrders = async (quantity, page, search, from, to) => {
  try {
    const res = await axios.get(
      `${baseUrl}/closed_trades_history?from=${from}&to=${to}&quantity=${quantity}&page=${page}&search=${search}`,
      { headers: headers }
    );
    return res;
  } catch (error) {
    throw error; // Asegúrate de lanzar el error para manejarlo en el lugar donde se llama a la función
  }
};

const getSignalPerformance = async (signal, year, month = "") => {
  try {
    const res = await axios.get(
      `${baseUrl}/graphics?signal_acc=${signal}&year=${year}&month=${month}`,
      { headers: headers }
    );
    return res;
  } catch (error) {
    throw error; // Asegúrate de lanzar el error para manejarlo en el lugar donde se llama a la función
  }
};

const SetProfilePictureRequest = async (formData) => {
  try {
    const response = await axios.post(`${baseUrl}/upload-profile-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: "Bearer " + token  
      },
    });
    console.log(response);
    return response;
  } catch (error) {
    throw error; // Lanza el error para manejarlo en el componente
  }
};

const newMessage = async (parameter) => {
  try {
    const response = await axios.put(`${baseUrl}/chat`, parameter,
      { headers: headers }
    );
    
    console.log(response);
    return response;
  } catch (error) {
    throw error; // Lanza el error para manejarlo en el componente
  }
};

const MarkReadMessages = async () => {
  try {
    const response = await axios.post(`${baseUrl}/chat`, null, {
      headers: headers
    });
    
    console.log(response);
    return response;
  } catch (error) {
    throw error; // Lanza el error para manejarlo en el componente
  }
};
const getChats = async (signalAcc) => {
  try {
    const response = await axios.get(`${baseUrl}/chat`, {
      params: { signal_acc: signalAcc }, // Enviar el parámetro como query string
      headers: headers
    });
    
    console.log(response);
    return response;
  } catch (error) {
    throw error; // Lanza el error para manejarlo en el componente
  }
};

const methods = {
  getSlaves,
  deleteMaster,
  editMaster,
  getFollowerInformation,
  makeVisible,
  makeInvisible,
  reactivateAccount,
  masterHistoricalSettings,
  LoadSignalOpenOrders,
  LoadSignalClosedOrders,
  SetProfilePictureRequest,
  getSignalPerformance,
  newMessage,
  MarkReadMessages,
  getChats
};

export default methods;
