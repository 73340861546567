import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import UserServices from "../../services/user.js";
import * as styles from "./Styles.js";
import {
  Box,
  Link,
  Button,
  Popover,
  IconButton,
  Typography,
} from "@mui/material";
import LanguageSelector from "../Generals/Selectors/languageSelector.js";
import logo_communitraders from "../../assets/logos/Logo-communitraders.svg";
import DashboardBackground from "../Backgrounds/DashboardBackground";
import SwitchBasic from "../Generals/Switches/Switch.js";
import followerService from "../../services/follower.js";
import userService from "../../services/user";
import NotificationModal from "../Generals/Notifications/NotificationModal.js";
import LoadingSpinner from "../Generals/Loaders/SpinLoader.js";
import SocialHubFollower from "./SocialHubFollower.js";
import profileImage from "../../assets/SignalTestImage/profileImage.png";
import {
  useBreakpoint,
  StatusProvider,
} from "../../config/BreakpointContext.js";
import MenuIcon from "@mui/icons-material/Menu"; // Ícono de menú
import CloseIcon from "@mui/icons-material/Close";

const NavbarAndSidebar = (props) => {
  //#region "Instantiation of States"
  const { isXs, isMd } = useBreakpoint();
  const [userName, setUserName] = useState("");
  const [avatarLetter, setAvatarLetter] = useState("");
  const [avatarColor, setAvatarColor] = useState("");
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const url = cookies.get("url");
  const [strategyImage, setStrategyImage] = useState(profileImage);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfileModal, setopenProfileModal] = useState(null);
  const [openSocialModal, setOpenSocialModal] = useState(null);
  const [profileInfo, setProfileInfo] = useState({
    name: "",
    login: 0,
  });
  const [accountStatus, setAccountStatus] = useState([
    { name: "P&L", value: 0 },
    { name: "Margin", value: 0 },
    { name: "Free margin", value: 0 },
    { name: "Balance", value: 0 },
    { name: "Equity", value: 0 },
    { name: "Risk", value: 0 },
  ]);
  const [followerInformation, setFollowerInformation] = useState(false);
  const [signalLogin, setSignalLogin] = useState(0);
  const [copy, setCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSocial, setOpenSocial] = useState(false);
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
  const [openSidebar, setOpenSidebar] = useState(false);
  const statusValue = useMemo(
    () => ({
      accountStatus,
    }),
    [accountStatus]
  );

  const open = Boolean(anchorEl);
  const openProfile = Boolean(openProfileModal);
  const id = open ? "simple-popover" : undefined;

  const openSocialBool = Boolean(openSocialModal);
  const idSocial = openSocialBool ? "social-popover" : undefined;

  //#endregion

  //#region "Handlers"
  const handleProfileClick = (event) => {
    setopenProfileModal(event.currentTarget);
  };

  const handleOpenSideBar = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleProfileClose = () => {
    setopenProfileModal(null);
  };

  const handleHome = () => {
    navigate("/Follower/FollowingSettings");
  };

  const handleLogout = () => {
    navigate("/login");
  };

  const handleCopyEvent = (event) => {
    if (event.detail.message !== "") {
      setFollowerInformation(event.detail.message.data);
      setCopy(event.detail.message.data.copy_mode);
    } else {
      setFollowerInformation(undefined);
      setCopy(false);
    }
  };

  const handleSetCopy = (event) => {
    setModalProps({
      open: true,
      iconType: "warning",
      title: !event.target.checked
        ? "Are you sure you want to disable copying?"
        : "Are you sure you want to enable copying?",
      message: !event.target.checked
        ? "If you disable copying of trades, none of the trades opened by the strategy followed will be copied."
        : "If you enable copying of trades, all trades opened by the strategy followed will be copied.",
      onAcceptLabel: "Accept",
      onRefuseLabel: "Cancel",
      onAccept: () => handleConfirmChangeCopy(event),
      onRefuse: () => handleCloseNotification(),
    });
  };

  const handleConfirmChangeCopy = (event) => {
    setCopy(event.target.checked);
    const followingInfo = {
      ...followerInformation,
      connect: event.target.checked,
    };
    UpdateFollowing(followingInfo);
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };
  //#endregion

  //#region "Request Functions"
  const RequestDashboardData = async () => {
    try {
      const response = await UserServices.getStatus();
      if (response.data != null) {
        setAccountStatus([
          { name: "P&L", value: response.data.data.pnl },
          { name: "Margin", value: response.data.data.margin },
          { name: "Free margin", value: response.data.data.free_margin },
          { name: "Balance", value: response.data.data.balance },
          { name: "Equity", value: response.data.data.equity },
        ]);
        setProfileInfo({
          name: response.data.data.name,
          login: response.data.data.account,
        });
      }
    } catch (error) {
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message:
          "An error has occurred while loading the status information. Please try reloading, if the problem persists please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
  };

  const RequestFollowerInfo = async () => {
    try {
      const response = await userService.getInformation();
      if (response.data != null) {
        setFollowerInformation(response.data.data);
        setSignalLogin(response.data.signal_followed);
        setCopy(response.data.data.connect);
        RequestSignalData(response.data.data.signal_followed);
      }
    } catch (error) {
      setFollowerInformation(undefined);
      setCopy(false);
      if (
        error.response.data.message !== "This account does not follow a signal"
      ) {
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message:
            "An unexpected error has occurred, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    }
  };

  const RequestSignalData = async (signalLogin) => {
    try {
      const response = await userService.getSignalInformation(signalLogin);
      if (response.data != null) {
        if (response.data.data.image.includes("/")) {
          setStrategyImage(`${url}/${response.data.data.image}`);
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateFollowing = async (followingInfo) => {
    try {
      setLoading(true);
      const response = await followerService.editFollower(followingInfo);
      if (response.data != null) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setCopy(!copy);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Error setting copy status",
        message:
          "An unexpected error has occurred. Please, try again, if the problem persists contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };

  const getButtonColor = (menuItem) => {
    if (props.sidebarOption === menuItem) {
      return {
        backgroundColor: Colors.Secondary_50,
        color: "#15202A",
      };
    }
    return {};
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }
  //#endregion

  //#region "useEffect"
  useEffect(() => {
    RequestDashboardData();
    RequestFollowerInfo();

    window.addEventListener("copyStatus", handleCopyEvent);
    return () => {
      window.removeEventListener("copyStatus", handleCopyEvent);
    };
  }, []);

  useEffect(() => {
    if (profileInfo.name) {
      setAvatarLetter(profileInfo.name.split(" ")[0][0]);
      setAvatarColor(stringToColor(profileInfo.name));
    }
  }, [profileInfo.name]);

  //#endregion

  //#region "HTML"
  return (
    <StatusProvider value={statusValue}>
      <Box sx={{ height: "100%", margin: "0", padding: "0" }}>
        <DashboardBackground></DashboardBackground>
        <NotificationModal
          open={modalProps.open}
          onClose={handleCloseNotification}
          iconType={modalProps.iconType}
          title={modalProps.title}
          message={modalProps.message}
          onAcceptLabel={modalProps.onAcceptLabel}
          onAccept={modalProps.onAccept}
        />
        {loading ? <LoadingSpinner /> : <></>}
        <Box sx={styles.navbar}>
          <IconButton
            sx={{
              backgroundColor: "transparent",
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={(e) => handleHome()}
          >
            <img src={logo_communitraders} alt="Tradeview" width={"200"} />
          </IconButton>
          {isMd && (
            <Box sx={styles.statusBox}>
              {accountStatus.map((item, index) => (
                <Box sx={styles.statusItem} key={index}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      lineHeight: "1.4",
                      color: Colors.Secondary_700,
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_3_Bold,
                      lineHeight: "1.4",
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          {isMd && (
            <Box sx={styles.perfilContainer}>
              <LanguageSelector></LanguageSelector>
              <Button sx={styles.profileBox} onClick={handleProfileClick}>
                <Avatar
                  sx={{
                    alt: userName,
                    bgcolor: avatarColor,
                    width: 20,
                    height: 20,
                    fontSize: 15,
                    padding: 2,
                  }}
                >
                  {avatarLetter}
                </Avatar>
                <Box sx={styles.nameBox}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      lineHeight: "1",
                    }}
                  >
                    Hello
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_3_Bold,
                      lineHeight: "1",
                    }}
                  >
                    {profileInfo.name.split(" ")[0]}
                  </Typography>
                </Box>
                <span className="material-symbols-outlined">expand_more</span>
              </Button>
              <Popover
                id={id}
                open={openProfile}
                anchorEl={openProfileModal}
                onClose={handleProfileClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  sx: {
                    maxHeight: "416px", // Altura máxima del popover para evitar expandir demasiado
                    overflowY: "hidden", // Habilita el scroll si el contenido es grande
                  },
                }}
              >
                <Box sx={styles.modalProfile}>
                  <Box sx={styles.modalProfileTitle}>
                    <Avatar
                      sx={{
                        alt: userName,
                        bgcolor: avatarColor,
                        width: 20,
                        height: 20,
                        fontSize: 15,
                        padding: 2,
                      }}
                    >
                      {avatarLetter}
                    </Avatar>
                    <Box sx={styles.nameBox}>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Body_3_Bold,

                          lineHeight: "1",
                        }}
                      >
                        {profileInfo.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          lineHeight: "1",
                        }}
                      >
                        {profileInfo.login}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    {/* <Button sx={styles.profileSelector}>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: Colors.Secondary_900 }}
                      >
                        settings
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          width: "100%",
                        }}
                      >
                        Settings
                      </Typography>
                    </Button> */}
                    <Button sx={styles.profileSelector} onClick={handleLogout}>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: Colors.Secondary_900 }}
                      >
                        logout
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          width: "100%",
                        }}
                      >
                        Log Out
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>
          )}
          {isXs && (
            <IconButton onClick={handleOpenSideBar}>
              <MenuIcon />
            </IconButton>
          )}
        </Box>
        {isMd && (
          <Box sx={styles.sideBar}>
            <Box sx={styles.infoSidebar}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: TextVariables.Body_4_Bold,
                  lineHeight: 1,
                }}
              >
                Follower
              </Typography>
              <Box sx={styles.sidebarNameContainer}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Caption_1,
                    color: "rgba(255, 255, 255, 0.8)",
                    lineHeight: 1,
                  }}
                >
                  {profileInfo.name}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Caption_1,
                    color: "rgba(255, 255, 255, 0.8)",
                    lineHeight: 1,
                  }}
                >
                  {profileInfo.login}
                </Typography>
              </Box>
              <Box sx={styles.visibilityBox}>
                <Typography sx={{ fontFamily: TextVariables.Body_4_Bold }}>
                  Copy status
                </Typography>
                <SwitchBasic
                  value={copy}
                  disabled={followerInformation !== undefined ? false : true}
                  handleChange={handleSetCopy}
                />
              </Box>
            </Box>
            <Box sx={styles.nav}>
              <Link
                sx={styles.tab}
                style={getButtonColor("FollowingSettings")}
                component={RouterLink}
                to="/Follower/FollowingSettings"
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "20px" }}
                >
                  trending_up
                </span>
                Following settings
              </Link>
              <Link
                sx={styles.tab}
                style={getButtonColor("Discover")}
                component={RouterLink}
                to="/Follower/Discover"
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "20px" }}
                >
                  group
                </span>
                Discover
              </Link>
              <Link
                sx={styles.tab}
                style={getButtonColor("Orders")}
                component={RouterLink}
                to="/Follower/Orders"
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "20px" }}
                >
                  account_balance_wallet
                </span>
                Orders
              </Link>
            </Box>
          </Box>
        )}
        {isXs && openSidebar && (
          <Box sx={styles.smallSidebarContainer}>
            <Box sx={styles.EmptySidebar}></Box>
            <Box sx={styles.sideBarSmall}>
              <IconButton
                sx={styles.closeIconContainer}
                onClick={handleOpenSideBar}
              >
                <CloseIcon sx={{ color: "white" }} />
              </IconButton>
              <Box sx={styles.infoSidebarSmall}>
                <Box sx={styles.profileBoxSmall}>
                  <Avatar
                    sx={{
                      alt: userName,
                      bgcolor: avatarColor,
                      width: 20,
                      height: 20,
                      fontSize: 15,
                      padding: 2,
                    }}
                  >
                    {avatarLetter}
                  </Avatar>
                  <Box sx={styles.nameBox}>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1,
                        lineHeight: "1",
                        color: "white",
                      }}
                    >
                      Hello {profileInfo.name.split(" ")[0]}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Body_3_Bold,
                        lineHeight: "1",
                        color: "white",
                      }}
                    >
                      {profileInfo.login}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.infoSidebarSmall}>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: TextVariables.Body_4_Bold,
                    lineHeight: 1,
                  }}
                >
                  Follower
                </Typography>
                <Box sx={styles.sidebarNameContainer}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: 1,
                    }}
                  >
                    {profileInfo.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: 1,
                    }}
                  >
                    {profileInfo.login}
                  </Typography>
                </Box>
                <Box sx={styles.visibilityBox}>
                  <Typography sx={{ fontFamily: TextVariables.Body_4_Bold }}>
                    Copy status
                  </Typography>
                  <SwitchBasic
                    value={copy}
                    disabled={followerInformation !== undefined ? false : true}
                    handleChange={handleSetCopy}
                  />
                </Box>
              </Box>
              <Box sx={styles.nav}>
                <Link
                  sx={styles.tab}
                  style={getButtonColor("FollowingSettings")}
                  component={RouterLink}
                  to="/Follower/FollowingSettings"
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "20px" }}
                  >
                    trending_up
                  </span>
                  Following settings
                </Link>
                <Link
                  sx={styles.tab}
                  style={getButtonColor("Discover")}
                  component={RouterLink}
                  to="/Follower/Discover"
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "20px" }}
                  >
                    group
                  </span>
                  Discover
                </Link>
                <Link
                  sx={styles.tab}
                  style={getButtonColor("Orders")}
                  component={RouterLink}
                  to="/Follower/Orders"
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "20px" }}
                  >
                    account_balance_wallet
                  </span>
                  Orders
                </Link>
              </Box>
              <Box sx={styles.nav}>
                <Link sx={styles.tab} onClick={handleLogout}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "20px" }}
                  >
                    logout
                  </span>
                  Log out
                </Link>
              </Box>
            </Box>
          </Box>
        )}
        <Box sx={isMd === true ? styles.content : styles.contentSmall}>
          {props.children}
        </Box>

        <SocialHubFollower
          followerInformation={followerInformation}
          signalImage={strategyImage}
          signalAccount={signalLogin}
        ></SocialHubFollower>
      </Box>
    </StatusProvider>
  );
  //#endregion
};

export default NavbarAndSidebar;
