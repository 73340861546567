import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, TextField, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import * as ColorsVariables from "../../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import MultiSelector from "../../Generals/Selectors/MultiSelector.js";
import Button3px from "../../Generals/Buttons/3pxButton.js";
import followerServices from "../../../services/follower"; // Importación del servicio
import { SetSymbols } from "../../../Tools/LocalStorage.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import TextIconButton from "../../Generals/Buttons/TextIconButton.js";
import AssetSymbolFilter from "../../Generals/Modals/AssetSymbolFilter.js";

const StyleButton = styled(Button)(({ active }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  backgroundColor: active ? "#ECF1F7" : "rgba(255, 255, 255, 0)",
  textTransform: "none",
  maxHeight: "33px",
  marginRight: 1,
  padding: "12px",
  border: active ? "2px solid #A0AFBC" : "none",
  borderRadius: "8px",
  paddingLeft: "10px",
  paddingRight: "10px",
  fontFamily: TextVariables.Caption_1_Bold,
  color: "rgba(55, 55, 55, 1)",
}));

const FilterComponent = ({
  selectedAssets,
  selectedCurrencies,
  handleAssetChange,
  handleCurrencyChange,
  search,
  handleSearchChange,
  handleSearch,
  filter,
  handleFilterChange,
  sortOrder,
}) => {
  const { isXs, isMd } = useBreakpoint();
  const [t] = useTranslation("global");
  const [symbolsData, setSymbolsData] = useState({});
  const [filteredCurrencies, setFilteredCurrencies] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  // Recuperar selecciones guardadas al montar el componente
  useEffect(() => {
    const savedAssets = localStorage.getItem("selectedAssets");
    const savedCurrencies = localStorage.getItem("selectedCurrencies");
    const savedSymbols = localStorage.getItem("symbolsData");

    if (savedAssets && JSON.parse(savedAssets) !== selectedAssets) {
      handleAssetChange({ target: { value: JSON.parse(savedAssets) } });
    }
    if (savedCurrencies && JSON.parse(savedCurrencies) !== selectedCurrencies) {
      handleCurrencyChange({ target: { value: JSON.parse(savedCurrencies) } });
    }

    if (savedSymbols) {
      setSymbolsData(JSON.parse(savedSymbols));
    } else {
      const fetchSymbols = async () => {
        try {
          const response = await followerServices.getAllSymbols();
          SetSymbols(response);
          const symbols = response.data.data.symbols;

          // Guardar los símbolos en localStorage
          localStorage.setItem("symbolsData", JSON.stringify(symbols));

          setSymbolsData(symbols);
        } catch (error) {
          console.error("Error fetching symbols", error);
        }
      };

      fetchSymbols();
    }
  }, []);

  const handleCloseNotification = (prevModalProps) => {
    setOpenFilter(false);
  };

  const handleOpenFIlter = () => {
    setOpenFilter(true);
  };

  // Guardar selecciones en localStorage cada vez que se actualicen
  useEffect(() => {
    if (selectedAssets.length > 0) {
      localStorage.setItem("selectedAssets", JSON.stringify(selectedAssets));
    }
  }, [selectedAssets]);

  useEffect(() => {
    if (selectedCurrencies.length > 0) {
      localStorage.setItem(
        "selectedCurrencies",
        JSON.stringify(selectedCurrencies)
      );
    }
  }, [selectedCurrencies]);

  // Filtrar las divisas en función de los activos seleccionados
  useEffect(() => {
    if (
      selectedAssets.length === 0 ||
      !symbolsData ||
      Object.keys(symbolsData).length === 0
    ) {
      setFilteredCurrencies([]);
    } else {
      const availableCurrencies = selectedAssets.reduce((acc, asset) => {
        const currenciesForAsset = Object.keys(symbolsData).filter(
          (symbol) => symbolsData[symbol] === asset
        );
        return [...new Set([...acc, ...currenciesForAsset])];
      }, []);
      setFilteredCurrencies(availableCurrencies);
    }
  }, [selectedAssets, symbolsData]);

  //#region "HTML"
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMd ? "row" : "column",
        justifyContent: "space-between",
        alignItems: isMd ? "flex-end" : "flex-start",
        width: "100%",
        gap: Spaces.Size_3,
      }}
    >
      <AssetSymbolFilter
        open={openFilter}
        handleAssetChange={handleAssetChange}
        handleCurrencyChange={handleCurrencyChange}
        selectedAssets={selectedCurrencies}
        filteredCurrencies={filteredCurrencies}
        selectedCurrencies={selectedCurrencies}
        onClose={handleCloseNotification}
      />
      {isMd && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: "16px",
          }}
        >
          {/* Box Assets */}
          <MultiSelector
            title={t("follower.Asset")}
            options={["Forex", "CFDs", "Crypto", "Metals"]}
            selectedValues={selectedAssets}
            handleChange={handleAssetChange}
            width="184px"
          />
          {/* Box Currency */}
          <MultiSelector
            title={t("follower.Currency")}
            options={filteredCurrencies}
            selectedValues={selectedCurrencies}
            handleChange={handleCurrencyChange}
            width="132px"
          />

          {/* Box Search */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "row",
              gap: Spaces.Size_2,
            }}
          >
            <TextField
              value={search}
              onChange={handleSearchChange}
              placeholder={t("follower.Search by strategy name")}
              sx={{
                width: "184px",
                height: "38px",
                padding: "0px",
                fontFamily: TextVariables.Overline,
                border: `1px solid ${Colors.Secondary_400}`,
                borderRadius: "8px",
                "& .MuiOutlinedInput-root": {
                  "& .MuiInputBase-input": {
                    padding: Spaces.Size_3,
                    fontFamily: TextVariables.Overline,
                    color: ColorsVariables.Colors.Secondary_900,
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      marginRight: "0px",
                    }}
                  >
                    <SearchIcon padding={0} />
                  </InputAdornment>
                ),
                sx: {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              }}
            />
            <Button3px
              label={"Search"}
              color={"White"}
              backgroundColor={Colors.Error_500}
              HandleOnClick={handleSearch}
            ></Button3px>
          </Box>
        </Box>
      )}
      {!isMd && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            gap: "16px",
          }}
        >
          {/* Box Search */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "row",
              gap: Spaces.Size_2,
            }}
          >
            <TextField
              value={search}
              onChange={handleSearchChange}
              placeholder={t("follower.Search by strategy name")}
              sx={{
                width: "184px",
                height: "38px",
                padding: "0px",
                fontFamily: TextVariables.Overline,
                border: `1px solid ${Colors.Secondary_400}`,
                borderRadius: "8px",
                "& .MuiOutlinedInput-root": {
                  "& .MuiInputBase-input": {
                    padding: Spaces.Size_3,
                    fontFamily: TextVariables.Overline,
                    color: ColorsVariables.Colors.Secondary_900,
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      marginRight: "0px",
                    }}
                  >
                    <SearchIcon padding={0} />
                  </InputAdornment>
                ),
                sx: {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              }}
            />
            <Button3px
              label={"Search"}
              color={"White"}
              backgroundColor={Colors.Error_500}
              HandleOnClick={handleSearch}
            ></Button3px>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextIconButton
              logo={"filter_alt"}
              label={"Filters"}
              HandleOnClick={handleOpenFIlter}
            ></TextIconButton>
          </Box>
        </Box>
      )}

      {/* Box Botones del Filter */}
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {["All", "Most popular", "Low fees", "High risk", "Low risk"].map(
          (filterName) => (
            <StyleButton
              sx={{
                padding: "12px",
                borderRadius: "8px",
                border:
                  sortOrder.toLowerCase() ===
                  filterName.toLowerCase().replace(" ", "")
                    ? "2px solid #A0AFBC"
                    : "0px solid",
                fontFamily: TextVariables.Caption_1_Bold,
                background:
                  sortOrder.toLowerCase() ===
                  filterName.toLowerCase().replace(" ", "")
                    ? "#ECF1F7"
                    : "transparent",
              }}
              key={filterName}
              onClick={() => handleFilterChange(filterName)}
            >
              {t(`follower.${filterName}`)} {/* Traducir */}
            </StyleButton>
          )
        )}
      </Box>
    </Box>
  );
  //#endregion
};

export default FilterComponent;
