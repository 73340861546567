import { Tooltip } from '@mui/material';
import InfoIcon from "../../../assets/info.png";
import "../Inputs/inputs.scss";

function TextInput({
  id,
  name,
  value,
  description,
  onChange,
  disabled,
  inputType,
  mode,
  ...props
}) {
  //This function gets props from sidebar component

  return (
    <div>
      <div className="labelField">
        <label className="settingLabel">{name}</label>
        <Tooltip title={description}>
          <img
            className="imageTitle"
            src={InfoIcon}
            alt="info"
            htmlFor="info-icon"
            data-tip
            data-for="infoTooltip"
          ></img>
        </Tooltip>
      </div>
      <textarea
        className={`                     
                    ${"inputText-" + mode}
                `}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        type={inputType}
      ></textarea>
    </div>
  );
}

export default TextInput;
