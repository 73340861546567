//Follower settings component render when the user click the setting button in sidebarfollower component
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../../css/signal/signalProfile.scss";
import { useTranslation } from "react-i18next";
import userService from "../../../services/user.js";
import signalService from "../../../services/signalProvider.js";
import followerService from "../../../services/follower.js";
import SearchIcon from "@mui/icons-material/Search";
import profileImage from "../../../assets/SignalTestImage/profileImage.png";
import { InfoDetailBox } from "../../Generals/Cards/infoDetailBox.js";
import { InfoDetailLogoBox } from "../../Generals/Cards/InfoDetailLogoBox.js";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import * as masterData from "../../../Master Data/masterData.js";
import { Spaces, mainBox } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { Box, Typography, Avatar, Button, TextField, Paper, InputAdornment, Grid } from "@mui/material";
import TableComponent from "../../Generals/Tables/TableComponent.js";
import TableToolTip from "../../Generals/Tooltips/TableToolTip.js";
import InfoChip from "../../Generals/Cards/InfoChip.js";
import IconComponent from "../../Generals/Icons/IconComponent.js";
import SocialHub from "../../SignalProvider/ProfileScreen/SocialHub";
import {
  ClearFollowingSettings,
  SetFollowingSettings
} from "../../../Tools/LocalStorage.js";
import StatisticalModule from "../../SignalProvider/ProfileScreen/StatisticalModule";
import FollowingModal from "../../Generals/Modals/FollowingModal.js";
import NotificationModal from "../../Generals/Notifications/NotificationModal.js";
import * as Encryptor from "../../../Tools/AESEncrypter"
import {
  SetUserInformation,
} from "../../../Tools/LocalStorage";
import Button3px from "../../Generals/Buttons/3pxButton";
import DateSelector from "../../Generals/Selectors/DateSelector";
import dayjs from "dayjs";
import {
  getLocalTimeZoneOffsetInSeconds
} from "../../../Tools/DateTimeConvertions";
import Cookies from "universal-cookie";
import { useDynamicStyles } from "../../SignalProvider/ProfileScreen/Styles";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import StatusModule from "../../Generals/Cards/StatusModule";


function DiscoverSignal(props) {
  //This function gets props from sidebarfollower component
  //#region "Instantiation of States"
  const { isXs, isMd } = useBreakpoint();
  const styles = useDynamicStyles();
  const [activeIndex, setActiveIndex] = useState(null);
  const [totalAmount, SetTotalAmount] = useState();
  const [page, setPage] = useState(1); // Estado para la página actual
  const [pageCount, setPageCount] = useState(0); // Estado para el número de páginas
  const [rows, setRows] = useState([]); // Estado para los datos de las filas
  const [loading, setLoading] = useState(false);
  const [strategyInformation, setStrategyInformation] = useState({
    best_instrument_1 : {
      name: ""
    },
    best_instrument_2 : {
      name: ""
    },
    best_instrument_3 : {
      name: ""
    },
  });
  const [strategyImage, setStrategyImage] = useState(profileImage);
  const [copied, setCopied] = useState(false);
  const [signalLogin, setSignalLogin] = useState(0);
  const [followingSettings, setFollowingSettings] = useState({});
  const [modalData, setModalData] = useState({});
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onContinueLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
    onContinue: undefined,
  });
  const [formValues, setFormValues] = useState({
    signal_followed: "0",
    copy_mode: "Proportional",
    risk_ratio: 1,
    drawdown_mode: "NoDrawdown",
    max_drawdown: 0,
    min_lot_size: 0,
    max_lot_size: 0,
    connect: true,
  });
  const [errors, setErrors] = useState({
    signal_followed: false,
    copy_mode: false,
    risk_ratio: false,
    drawdown_mode: false,
    max_drawdown: false,
    min_lot_size: false,
    max_lot_size: false,
    connect: true,
  });
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  
//#endregion

  //#region "Declaration of Normal Variables or Constants"
  const cookies = new Cookies();
  const url = cookies.get("url");
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const formValuesRef = useRef(formValues);
  const columns = [
    {
      field: "ticket",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Ticket number">
          <Box sx={styles.tooltipBox}>
            {"Ticket "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "open_time",
      headerClassName: "header_table",
      renderHeader: () => <>{"Open time"}</>,
    },
    {
      field: "type",
      headerClassName: "header_table",
      renderHeader: () => <>{"Type"}</>,
    },
    {
      field: "symbol",
      headerClassName: "header_table",
      renderHeader: () => <>{"Symbol"}</>,
    },
    {
      field: "volume",
      headerClassName: "header_table",
      renderHeader: () => <>{"Volume"}</>,
    },
    {
      field: "open_price",
      headerClassName: "header_table",
      renderHeader: () => <>{"Price"}</>,
    },
    {
      field: "sp",
      headerClassName: "header_table",
      renderHeader: () => <>{"Stop loss"}</>,
    },
    {
      field: "tp",
      headerClassName: "header_table",
      renderHeader: () => <>{"Take profit"}</>,
    },
    {
      field: "close_time",
      headerClassName: "header_table",
      renderHeader: () => <>{"Close time"}</>,
    },
    {
      field: "commission",
      headerClassName: "header_table",
      renderHeader: () => <>{"Commission"}</>,
    },
    {
      field: "swap",
      headerClassName: "header_table",
      renderHeader: () => <>{"Swap"}</>,
    },
    {
      field: "pnl",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Total profit">
          <Box sx={styles.tooltipBox}>
            {"Pnl "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "comment",
      headerClassName: "header_table",
      renderHeader: () => <>{"Comment"}</>,
    },
  ];
  //#endregion

  //#region "Handlers"
  const handleChangingFollow = (signal_followed, strategy_name) => {
    if (followingSettings !== "") {
      setModalProps({
        open: true,
        iconType: "warning",
        title: "A strategy is already being followed",
        message: `You are currently following strategy: ${followingSettings.strategy_followed}, do you want to stop following the current strategy and start following strategy: ${strategy_name}?`,
        onContinueLabel: "Continue",
        onContinue: () => handleUnfollow(signal_followed),
        onRefuseLabel: "Cancel",
        onRefuse: () => handleCloseNotification(),
      });
    }
  };

  const handleFollow = () => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues, // Mantener los campos anteriores
      signal_followed: signalLogin, // Modificar solo el campo 'name'
    }));
    setModalData({
      open: true,
      iconType: "info",
      title: "Please, read carefully",
      onAcceptLabel: "Use default settings",
      onRefuseLabel: "Customize settings",
      modalType: "follow",
      onRefuse: () => handleFollowCustom(),
      onAccept: () => handleFollowDefault(),
    });
  };

  const handleFollowCustom = () => {
    setModalData({
      open: true,
      title: "Customize settings",
      onAcceptLabel: "Save and follow",
      onRefuseLabel: "Cancel",
      modalType: "followCustom",
      onAccept: () => handleSubmit(),
      onRefuse: () => handleModalClose(),
    });
  };

  const handleFollowDefault = () => {
    setModalData({
      open: true,
      title: "Default settings",
      onAcceptLabel: "Follow",
      onRefuseLabel: "Customize settings",
      modalType: "followDefault",
      onAccept: () => handleSubmit(),
      onRefuse: () => handleFollowCustom(),
    });
  };

  const handleUnfollow = (signal_followed = "") => {
    setModalProps({
      open: true,
      iconType: "warning",
      title: "Are you sure you want to unfollow this strategy?",
      message:
        "You are about to stop following this trading strategy. If you decide to unfollow, you will no longer automatically copy the trades of the selected trader.",
      onAcceptLabel: "Continue",
      onRefuseLabel: "Cancel",
      onRefuse: () => handleCloseNotification(),
      onAccept: () => RequestUnfollow(signal_followed),
    });
  };
  const handleModalClose = () => {
    setModalData({
      open: false,
    });
    setFormValues({
      signal_followed: signalLogin,
      copy_mode: "Proportional",
      risk_ratio: 1,
      drawdown_mode: "NoDrawdown",
      max_drawdown: 0,
      min_lot_size: 0,
      max_lot_size: 0,
      connect: true,
    });
  };

  const handleChangePage = (page) => {
    setPage(page);
    RequestSettingsLogs(10, page);
  };
  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Actualiza los valores del formulario
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };



  const handleSubmit = () => {
    const currentFormValues = formValuesRef.current;
    let valid = true;
    let newErrors = {};
    // Verificar cada campo
    for (const [key, value] of Object.entries(currentFormValues)) {
      if (
        key === "min_lot_size" &&
        currentFormValues.max_lot_size !== 0 &&
        parseInt(value) >= currentFormValues.max_lot_size
      ) {
        newErrors[key] = true;
        valid = false;
      } else if (value === "" || value === false) {
        newErrors[key] = true;
        valid = false;
      } else {
        newErrors[key] = false;
      }
    }

    setErrors(newErrors);

    if (valid) {
      UpdateUserStrategy();
    } else {
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const handleToDate = (date) => {
    setToDate(date);
  };

  const handleSearch = () => {
    setPage(0);
    GetSignalClosedOrders(10, 0, search, fromDate, toDate, signalLogin);
  };
  //#endregion

  //#region "Requests"
  const handleSendCopyStatusEvent = (body) => {
    const event = new CustomEvent('copyStatus', {
      detail: { message: body }
    });
    
    // Emitir el evento
    window.dispatchEvent(event);
  };

  const UpdateUserStrategy = async () => {
    try {
      setLoading(true);

      const currentFormValues = formValuesRef.current;
      const response = await followerService.follow(currentFormValues);
      if (response.data != null) {
        SetFollowingSettings(response.data);
        handleSendCopyStatusEvent(response.data);
        setLoading(false);
        navigate("/Follower/FollowingSettings");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };
  const GetSignalClosedOrders = async (
    quantityPerPage,
    page,
    search,
    from,
    to,
    signalLogin
  ) => {
    try {
      setLoading(true);
      const dateFromSeconds =
      from / 1000 + getLocalTimeZoneOffsetInSeconds();
      const dateToSeconds =
      to / 1000 + getLocalTimeZoneOffsetInSeconds() + 86400;
      const response = await followerService.LoadSignalClosedOrders(
        quantityPerPage,
        page,
        search,
        dateFromSeconds,
        dateToSeconds,
        signalLogin
      );

      if (response.status === 200) {
        setLoading(false); //Stops loading spinning
        let actualRows = response.data.data.data.map((log, index) => {

          const dateOpen = new Date(log.open_time * 1000); // Convertimos segundos a milisegundos
          const yearOpen = dateOpen.getUTCFullYear();
          const monthOpen = String(dateOpen.getUTCMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
          const dayOpen = String(dateOpen.getUTCDate()).padStart(2, "0");
          const hoursOpen = String(dateOpen.getUTCHours()).padStart(2, "0");
          const minutesOpen = String(dateOpen.getUTCMinutes()).padStart(2, "0");
          const secondsFormattedOpen = String(dateOpen.getUTCSeconds()).padStart(2, "0");
          const formattedDateOpen = `${yearOpen}-${monthOpen}-${dayOpen} ${hoursOpen}:${minutesOpen}:${secondsFormattedOpen}`;

          const dateClose = new Date(log.close_time * 1000); // Convertimos segundos a milisegundos
          const yearClose = dateClose.getUTCFullYear();
          const monthClose = String(dateClose.getUTCMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
          const dayClose= String(dateClose.getUTCDate()).padStart(2, "0");
          const hoursClose = String(dateClose.getUTCHours()).padStart(2, "0");
          const minutesClose = String(dateClose.getUTCMinutes()).padStart(2, "0");
          const secondsFormattedClose = String(dateClose.getUTCSeconds()).padStart(2, "0");
          const formattedDateClose = `${yearClose}-${monthClose}-${dayClose} ${hoursClose}:${minutesClose}:${secondsFormattedClose}`;

          let row = {
            id: index,
            ticket: log.ticket,
            open_time: formattedDateOpen,
            type: log.type,
            symbol: log.symbol,
            volume: log.volume,
            open_price: log.open_price,
            sl: log.sl,
            tp: log.tp,
            close_time: formattedDateClose,
            close_price: log.close_price,
            commission: log.commission,
            swap: log.swap,
            pnl: log.pnl,
            comment: log.comment,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      } else {
        setLoading(false); //Stops loading spinning
      }
      return response.data;
    } catch (error) {
      if (error.response.data.message === "Login is required") {
        setLoading(false); //Stops loading spinning
        setModalProps({
          open: true,
          iconType: "error",
          title: "Session Expired",
          message:
            "Your session has expired, please log in again and try again.",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
        navigate("/");
      } else {
        setLoading(false); //Stops loading spinning
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message:
            "An unexpected error has occurred getting closed orders, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    }
  };
  const RequestUnfollow = async (signal_followed) => {
    try {
      setLoading(true);
      const response = await followerService.stopFollowing();
      if (response.data != null) {
        setLoading(false);
        ClearFollowingSettings();
        setFollowingSettings("");
        if (signal_followed !== "") {
          handleCloseNotification();
          handleFollow(signal_followed);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Error unfollowing strategy",
        message:
          "An unexpected error has occurred while the strategy was no longer followed, please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };
  const RequestFollowerInfo = async () => {
    try {
      setLoading(true);
      const response = await userService.getInformation();
      if (response.data != null) {
        setLoading(false);
        setFollowingSettings(response.data.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setFollowingSettings("");
      if(error.response.data.message !== "This account does not follow a signal"){
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message: "An unexpected error has occurred, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    }
    setLoading(false);
  };

  const RequestDashboardData = async (signalLogin) => {
    try {
      setLoading(true);
      const response = await userService.getSignalInformation(signalLogin);
      if (response.data != null) {
        setLoading(false);
        if(response.data.data.visibility === false){
          navigate("/Follower/Discover");
        }
        else{
          setStrategyInformation(response.data.data);
          SetUserInformation(response);
          if(response.data.data.image.includes("/")){
            setStrategyImage(`${url}/${response.data.data.image}`);
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      navigate("/Follower/Discover");
    }
    setLoading(false);
  };

  const RequestSettingsLogs = async (quantity, page) => {
    try {
      setLoading(true);
      const response = await signalService.masterHistoricalSettings(
        quantity,
        page
      );
      if (response.data != null) {
        setLoading(false);
        let actualRows = response.data.data.data.map((log, index) => {
          let row = {
            id: index,
            disable_account_on_drawdown: log.disable_account_on_drawdown,
            drawdown_mode: log.drawdown_mode,
            fee_value: log.fee_value,
            max_drowdown: log.max_drowdown,
            message: log.message,
            strategy_name: log.strategy_name,
            strategy_type: log.strategy_type,
            time: log.time,
            visibility: log.visibility,
            ip: log.ip,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  //#endregion

  //#region "useEffects"
  useEffect(() => {
    formValuesRef.current = formValues;
  }, [formValues]);

  useEffect(() => {
    setFromDate(dayjs().startOf("day"));
    setToDate(dayjs().add(1, "day").startOf("day"));
    RequestFollowerInfo();
    const path = window.location.pathname;
    const signalLoginHashed = path.split("/").pop();
    const signalLoginCleared = Encryptor.revertUrlSafe(signalLoginHashed);
    const signalLoginDecrypt = Encryptor.decrypt(signalLoginCleared);
    console.log(signalLoginDecrypt);
    RequestDashboardData(signalLoginDecrypt);
    setSignalLogin(signalLoginDecrypt);
    GetSignalClosedOrders(10, 0, search, dayjs().startOf("day"), dayjs().add(1, "day").startOf("day"), signalLoginDecrypt);
  }, []);
  //#endregion

  //#region "HTML"
  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <FollowingModal
        modalData={modalData}
        formValues={formValues}
        errors={errors}
        handleModalClose={handleModalClose}
        handleChange={handleChange}
      />
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
        onRefuseLabel={modalProps.onRefuseLabel}
        onRefuse={modalProps.onRefuse}
        onContinueLabel={modalProps.onContinueLabel}
        onContinue={modalProps.onContinue}
      />
      <Box sx={styles.form}>
      {isXs && <StatusModule></StatusModule>}
        <Box sx={styles.profileSocialContainer}>
          <Box sx={styles.profileContainer}>
            <Box sx={styles.profileTitleBox}>
              <Box sx={styles.avatarTitleBox}>
                <Box>
                  <Avatar
                    alt="strategy image"
                    src={strategyImage}
                    sx={{ width: 95, height: 95 }}
                  ></Avatar>
                </Box>
                <Box sx={styles.descriptionBox}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_3_Bold,
                    }}
                  >
                    {strategyInformation.strategy_name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                    }}
                  >
                    {strategyInformation.description}
                  </Typography>
                  <Button
                    onClick={
                      followingSettings !== ""
                        ? (e) =>
                            handleChangingFollow(
                              signalLogin,
                              strategyInformation.strategyName
                            )
                        : (e) => handleFollow(signalLogin)
                    }
                    sx={styles.shareLinkButton}
                  >
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1_Bold,
                        lineHeight: 1,
                      }}
                    >
                      {followingSettings !== "" && followingSettings.signal_followed === followingSettings.signalLoginDecrypt
                        ? "Unfollow"
                        : "Follow"}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.strategyOverviewBox}>
              <Box sx={styles.StrategyOverviewTitleBox}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_4_Bold,
                    lineHeight: 1,
                  }}
                >
                  Strategy overview
                </Typography>
                <Box sx={styles.chipsContainer}>
                  <InfoChip
                    text={`Currency: ${strategyInformation.currency}`}
                  />
                  <InfoChip
                    text={`Strategy type: ${strategyInformation.strategy_type}`}
                  />
                </Box>
              </Box>
              <Grid container spacing={2} sx={{height: "auto"}}>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Leverage"}
                    detail={strategyInformation.leverage}
                    logo={"page_info"}
                  ></InfoDetailBox>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Account type"}
                    detail={strategyInformation.account_type}
                    logo={"assignment_ind"}
                  ></InfoDetailBox>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Drawdown"}
                    detail={strategyInformation.max_drawdown}
                    logo={"swap_vert"}
                  ></InfoDetailBox>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Fee"}
                    detail={`${strategyInformation.fee_value}%`}
                    logo={"sell"}
                  ></InfoDetailBox>
                </Grid>
              </Grid>
            </Box>
            
            <Box sx={styles.strategyOverviewBox}>
              <Box sx={styles.StrategyOverviewTitleBox}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_4_Bold,
                    lineHeight: 1,
                  }}
                >
                  Most traded symbols
                </Typography>
              </Box>
              <Grid container spacing={2} sx={{height: "auto"}}>
                <Grid item xs={12} sm={4} md={4}>
                <InfoDetailLogoBox
                  title={strategyInformation.best_instrument_1.name}
                  detail={`${strategyInformation.best_instrument_1.percentage}%`}
                  asset1={
                    masterData.assets.find(
                      (asset) =>
                        asset.label ===
                        strategyInformation.best_instrument_1.name.slice(0, 3)
                    )?.flag ||
                    masterData.assets.find(
                      (asset) => asset.label === "defaultFlagPath"
                    )?.flag // Valor por defecto si no encuentra el asset
                  }
                  asset2={
                    masterData.assets.find(
                      (asset) =>
                        asset.label ===
                        strategyInformation.best_instrument_1.name.slice(3)
                    )?.flag ||
                    masterData.assets.find(
                      (asset) => asset.label === "defaultFlagPath"
                    )?.flag // Valor por defecto si no encuentra el asset
                  }
                ></InfoDetailLogoBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                <InfoDetailLogoBox
                  title={strategyInformation.best_instrument_2.name}
                  detail={`${strategyInformation.best_instrument_2.percentage}%`}
                  asset1={
                    masterData.assets.find(
                      (asset) =>
                        asset.label ===
                        strategyInformation.best_instrument_2.name.slice(0, 3)
                    )?.flag ||
                    masterData.assets.find(
                      (asset) => asset.label === "defaultFlagPath"
                    )?.flag // Valor por defecto si no encuentra el asset
                  }
                  asset2={
                    masterData.assets.find(
                      (asset) =>
                        asset.label ===
                        strategyInformation.best_instrument_2.name.slice(3)
                    )?.flag ||
                    masterData.assets.find(
                      (asset) => asset.label === "defaultFlagPath"
                    )?.flag // Valor por defecto si no encuentra el asset
                  }
                ></InfoDetailLogoBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                <InfoDetailLogoBox
                  title={strategyInformation.best_instrument_3.name}
                  detail={`${strategyInformation.best_instrument_3.percentage}%`}
                  asset1={
                    masterData.assets.find(
                      (asset) =>
                        asset.label ===
                        strategyInformation.best_instrument_3.name.slice(0, 3)
                    )?.flag ||
                    masterData.assets.find(
                      (asset) => asset.label === "defaultFlagPath"
                    )?.flag // Valor por defecto si no encuentra el asset
                  }
                  asset2={
                    masterData.assets.find(
                      (asset) =>
                        asset.label ===
                        strategyInformation.best_instrument_3.name.slice(3)
                    )?.flag ||
                    masterData.assets.find(
                      (asset) => asset.label === "defaultFlagPath"
                    )?.flag // Valor por defecto si no encuentra el asset
                  }
                ></InfoDetailLogoBox>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <SocialHub signalInformation={strategyInformation} signalImage={strategyImage}></SocialHub>
        </Box>
        <StatisticalModule strategyInfo = {strategyInformation}></StatisticalModule>
        <Paper sx={styles.settingsLogsContainer}>
          <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
            Close orders
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMd ? "row" : "column",
              alignItems: isMd ? "flex-end" : "flex-start",
              justifyContent: "flex-start",
              gap: Spaces.Size_5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: Spaces.Size_5,
              }}
            >
              <DateSelector
                title={t("follower.From")}
                value={fromDate}
                handleChange={handleFromDate}
              ></DateSelector>
              <DateSelector
                title={t("follower.To")}
                value={toDate}
                handleChange={handleToDate}
              ></DateSelector>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: Spaces.Size_5,
                width: "100%",
              }}
            >
              <TextField
                value={search}
                onChange={handleSearchChange}
                placeholder={t("follower.Search order")}
                sx={{
                  width: isMd ? "263px" : "100%",
                  maxHeight: "38px",
                  maxWidth: "400px",
                  padding: "0px",
                  fontFamily: TextVariables.Overline,
                  border: `1px solid ${Colors.Secondary_400}`,
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& .MuiInputBase-input": {
                      padding: Spaces.Size_3,
                      fontFamily: TextVariables.Overline,
                      color: Colors.Secondary_900,
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        marginRight: "0px",
                      }}
                    >
                      <SearchIcon padding={0} />
                    </InputAdornment>
                  ),
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Elimina el borde
                    },
                  },
                }}
              />
              <Button3px
                label={"Search"}
                color={Colors.Secondary_700}
                backgroundColor={Colors.Gray_200}
                HandleOnClick={handleSearch}
              ></Button3px>
            </Box>
          </Box>
          <TableComponent
            rows={rows}
            page={page}
            totalAmount={totalAmount}
            pageCount={pageCount}
            handleChangePage={handleChangePage}
            columns={columns}
          />
        </Paper>
      </Box>
    </Box>
  );
  //#endregion


}

export default DiscoverSignal;
