import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Typography, Paper, Box, Checkbox, Button } from "@mui/material";
import * as styles from "./Styles.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import logo_communitraders from "../../assets/logos/Logo-communitraders.svg";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import TitledInput from "../Generals/Inputs/TitledInput.js";
import TitledPasswordInput from "../../components/Generals/Inputs/TitledPasswordInput.js";
import TitledSelector from "../Generals/Selectors/TitledSelector.js";
import ApplyButton from "../Generals/Buttons/ApplyButton.js";
import * as MasterData from "../../Master Data/masterData.js";
import LoadingSpinner from "../Generals/Loaders/SpinLoader.js";
import NotificationModal from "../Generals/Notifications/NotificationModal.js";
import userService from "../../services/user.js";
import loginService from "../../services/login.js";
import { useTranslation } from "react-i18next";

function SignalRegisterView(props) {
  //#region "Instantiation of States"
  const [t] = useTranslation("global");
  const [usu, setUsu] = useState("");
  const [pass, setPass] = useState("");
  const [role, setRole] = useState("");
  const [platform, setPlatform] = useState("");
  const [server, setServer] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ip, setIp] = useState("");
  const [showUrl, setShowUrl] = useState(false);
  const [errors, setErrors] = useState({
    usu: false,
    pass: false,
    role: false,
    platform: false,
    server: false,
    checked: false,
  });
  const [formValues, setFormValues] = useState({
    usu: "",
    pass: "",
    role: "signal",
    platform: "",
    server: "",
    checked: false,
  });
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });

  const navigate = useNavigate();
  const cookies = new Cookies();
  let title = "";
  //#endregion  
  //-----------------------------------------------------------------------
  //#region Credentials and token cookies deleted
  //-----------------------------------------------------------------------

  cookies.remove("user", { path: "/" });
  cookies.remove("account type", { path: "/" });
  cookies.remove("token", { path: "/" });

  const env = process.env.REACT_APP_URL;
 //#endregion

  //#region "Handlers"
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    let valid = true;
    let newErrors = {};

    // Verificar cada campo
    for (const [key, value] of Object.entries(formValues)) {
      if (value === "" || value === false) {
        newErrors[key] = true;
        valid = false;
      } else {
        newErrors[key] = false;
      }
    }

    setErrors(newErrors);

    if (valid) {
      // Procesar el formulario
      handleRegister();
    } else {
      setModalProps({
        open: true,
        iconType: "error",
        title: "Please, fill the missing data",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({...prevModalProps, open: false});
  };

  const handleUserInput = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (inputValue.length <= 10) {
      setFormValues({
        ...formValues,
        usu: inputValue,
      });
    }
  };

  const handlePasswordInput = (event) => {
    if (event.target.value.length <= 30) {
      setFormValues({
        ...formValues,
        pass: event.target.value,
      });
    }
  };

  const handlePlatformSelector = (event) => {
    setFormValues({
      ...formValues,
      platform: event.target.value,
    });
  };

  const handleServerSelector = (event) => {
    setFormValues({
      ...formValues,
      server: event.target.value,
    });
    cookies.set("url", event.target.value, { path: "/" });
    const urlUpdatedEvent = new CustomEvent("updatedUrl", {
      detail: { value: event.target.value },
    });

    window.dispatchEvent(urlUpdatedEvent);
  };

  const handleDisclouserCheck = (event) => {
    setFormValues({
      ...formValues,
      checked: event.target.checked,
    });
  };

  const getIp = () => {
    setLoading(true); //Starts loading spinning
    userService
      .getIpClient //Calls the method getIpClient from user service (axios request)
      ()
      .then((response) => {
        //Waits for a response from the axios request
        setLoading(false); //Stops loading spinning
        return response;
      })
      .then((response) => {
        if (response !== null) {
          setIp(response.data); //If response is correct, sets ip into the hook ip.
        } else {
          console.log("not ip");
          getIp();
        }
      })
      .catch((error) => {
        setLoading(false); //Stops loading spinning
        console.log(error);
        getIp();
      });
  };

  const handleRegister = (e) => {
    //Calls login service to do login into the communitraders api
    setLoading(true);
    loginService
      .createMaster({
        //Login request body
        account: formValues.usu,
        password: formValues.pass,
        ip: ip,
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        //Checking out if the response return the login data
        if (response.data != null) {
          setModalProps({
            open: true,
            iconType: "success",
            title: "Welcome to your Signal Provider profile",
            message: "You've successfully created a Signal Provider profile for your MetaTrader account. Set up your strategy, invite your clients to follow it, and start earning rewards!",
            onAcceptLabel: "Continue",
            onAccept: () => handleLogin(),
          });
        } else {
          setModalProps({
            iconType: "error",
            title: "Server error",
            onAcceptLabel: "Continue",
            onAccept: () => handleCloseNotification(),
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if(error.response.data.message === "Is already an active signal provider"){
          setModalProps({
            open: true,
            iconType: "warning",
            title: "Account already registered as a Signal Provider",
            onAcceptLabel: "Continue",
            onAccept: () => handleCloseNotification(),
          });
        }
        else{
          setModalProps({
            open: true,
            iconType: "error",
            title: "Server error",
            message: "We encountered an issue while creating a Signal Provider profile for your account. Please verify the account information you provided and try again.",
            onAcceptLabel: "Continue",
            onAccept: () => handleCloseNotification(),
          });
        }

        setLoading(false);
      });
  };

  const handleLogin = (e) => {
    //Calls login service to do login into the communitraders api
    setLoading(true);
    loginService
      .login({
        //Login request body
        platform: formValues.platform,
        environment: formValues.server,
        role: formValues.role,
        account: formValues.usu,
        password: formValues.pass,
        ip: ip,
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        //Checking out if the response return the login data
        if (response.data != null) {
          var user = response.data;
          cookies.set("token", user.token, { path: "/" });
          const tokenUpdatedEvent = new CustomEvent("tokenUpdatedEvent", {
            detail: { value: user.token },
          });

          window.dispatchEvent(tokenUpdatedEvent);

          //If the response was correct, the credentials hooks are deleted
          setUsu("");
          setPass("");
          let loginMessage = "";

          // Checks out if the account type is signal or follower
          if (formValues.role === "signal") {
            //If the account type is signal, set the cookies with the access data (user, account type and token)
            cookies.set("user", formValues.usu, { path: "/" });
            cookies.set("account type", formValues.role, { path: "/" });
            console.log("Signal detected");
            navigate("/SignalProvider/Profile");
            //If the account type is signal the disclouser screen will be displayed
            loginMessage = "login.Welcome signal provider user";
          } else if (formValues.role === "follower") {
            //If the account type is follower, set the cookies with the access data (user, account type and token)
            cookies.set("user", formValues.usu, { path: "/" });
            cookies.set("account type", formValues.role, { path: "/" });
            console.log("Follower detected");
            navigate("/Follower/FollowingSettings");
          }
          //Show an alert message and redirect to disclouser screen

          //if the response is null a warning will be displayed
        } else {
          setModalProps({
            open: true,
            iconType: "error",
            title: t("login.Invalid access credentials"),
            onAcceptLabel: "Continue",
            onAccept: () => handleCloseNotification(),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setModalProps({
          open: true,
          iconType: "error",
          title: t("login.Bad request, please check your account information"),
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
        setLoading(false);
      });
  };
  //#endregion
  
  //#region "Requests"
  // Function to handler when a user click on show password button in login form, allows to see the password written by user
  
  const showPassword = (e) => {
    setShowUrl(!showUrl);
    var tipo = document.getElementById("user-password");
    if (tipo.type === "password") {
      tipo.type = "text";
    } else {
      tipo.type = "password";
    }
  };
  //#endregion
  
  //#region "useEffects"
  //USe Effect hook triggered once per render, it calls a function to request the user ip
  useEffect(() => {
    getIp();
  }, []);
  //#endregion
  
  //#region "HTML"
  return (
    <Box sx={styles.mainContainer}>
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
      />
      <Box sx={styles.logoContainer}>
        <img src={logo_communitraders} alt="Tradeview" width={"233"} />
        <Typography sx={{ fontFamily: TextVariables.H3, lineHeight: "100%" }}>
        Become a Signal Provider today!
        </Typography>
      </Box>
      <Box sx={styles.formContainer}>
        <Paper elevation={1} sx={styles.mainPaperForm}>
          <Paper elevation={1} sx={styles.paperForm}>
            <Box sx={styles.loginTitleBox}>
              <Typography
                sx={{ fontFamily: TextVariables.Body_2_Bold, lineHeight: "1" }}
              >
                Register as a Signal Provider
              </Typography>
              <Typography
                sx={{
                  fontFamily: TextVariables.Body_4,
                  color: Colors.Secondary_600,
                }}
              >
                Use your MetaTrader credentials to create a Signal Provider profile at CommuniTraders. Already a Signal? Sign in {" "}
                <Button
                  component="a"
                  href="/login"
                  sx={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    padding: 0,
                    margin: 0,
                    minWidth: "auto",
                    textTransform: "none",
                    background: "none",
                    fontFamily: TextVariables.Body_4,
                    verticalAlign: "0",
                    "&:hover": {
                      background: "none",
                      textDecoration: "underline",
                    },
                  }}
                >
                  here
                </Button>
                .
              </Typography>
            </Box>
            <Box sx={styles.loginInputsBox}>
              <TitledInput
                name="login"
                value={formValues.usu}
                title="MetaTrader account number"
                placeholder="Enter your login"
                handleChange={handleUserInput}
                error={errors.usu}
                helperText={
                  errors.usu ? (
                    <Typography sx={styles.emptyFieldLabel}>
                      {" "}
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      Please, please enter your user
                    </Typography>
                  ) : (
                    ""
                  )
                }
              ></TitledInput>
              <TitledPasswordInput
                name="password"
                title="Password"
                value={formValues.pass}
                type="password"
                placeholder="Enter your password"
                handleChange={handlePasswordInput}
                error={errors.pass}
                helperText={
                  errors.pass ? (
                    <Typography sx={styles.emptyFieldLabel}>
                      {" "}
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      Please, enter your password
                    </Typography>
                  ) : (
                    ""
                  )
                }
              ></TitledPasswordInput>
              <TitledSelector
                title="Platform"
                name="platform"
                value={formValues.platform}
                handleChange={handlePlatformSelector}
                options={MasterData.platformOptions}
                placeholder="Select a platform"
                error={errors.platform}
                helperText={
                  errors.platform ? (
                    <Typography sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      Please, select a platform
                    </Typography>
                  ) : (
                    ""
                  )
                }
              />
              <TitledSelector
                title="Server"
                name="server"
                value={formValues.server}
                handleChange={handleServerSelector}
                options={
                  formValues.platform === "metatrader4"
                    ? MasterData.serverOptionsMt4
                    : MasterData.serverOptionsMt5
                }
                error={errors.server}
                placeholder="Select a server"
                helperText={
                  errors.server ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        Please, select an option
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              />
            </Box>
            <Box sx={styles.disclaimerContainer}>
              <Checkbox
                checked={formValues.checked}
                onChange={handleDisclouserCheck}
                color="primary" // Cambia el color del checkbox si lo deseas
                sx={{ padding: "0px" }}
              />
              <Box>
                <Typography sx={{ fontFamily: TextVariables.Overline }}>
                  {" "}
                  By checking this box, I declare that I agree to the terms of
                  the CommuniTraders Acknowledgement and Agreement, which states
                  that Tradeview Financial Markets S.A.C (Tradeview SAC) has
                  made no representation regarding the potential profitability
                  of any signal service (Service) and does not endorse any of
                  them. I also acknowledge the significant risk of loss involved
                  in trading any instrument in any market. I agree that
                  Tradeview SAC will execute signals generated by the Service on
                  a best-effort basis. I accept the Communitraders Software
                  (Copier Software) 'as is' and agree that Tradeview SAC shall
                  have no liability for losses to my account and no
                  responsibility for advising me of any changes or updates to
                  the Copier Software or the Service. I acknowledge that it is
                  my responsibility to stay informed about my account activity,
                  the status of the Service, and the Copier Software. I agree
                  that Tradeview SAC is not responsible for any loss in my
                  account caused by mechanical, electronic, system, software, or
                  communication failure, or any other cause beyond Tradeview
                  SAC’s control. I hereby indemnify and hold harmless Tradeview
                  SAC, its principals, agents, directors, shareholders,
                  officers, and employees for any losses, costs, expenses, or
                  other liabilities (including reasonable attorney/accountant
                  fees) I may experience as a result of using the Copier
                  Software or the Service
                </Typography>
                {errors.checked && (
                  <Typography sx={styles.emptyFieldLabel}>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: Spaces.Size_3, color: "red" }}
                    >
                      error
                    </span>
                    Please, check the box to continue
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={styles.loginButtonContainer}>
              <ApplyButton HandleOnClick={handleSubmit}>Continue</ApplyButton>
            </Box>
          </Paper>
        </Paper>
      </Box>
    </Box>
  );
  //#endregion
}

export default SignalRegisterView;
