//Follower settings component render when the user click the setting button in sidebarfollower component
//#region Imports
import { useState, useEffect } from "react";
import "../../../css/follower/orders.scss";
import SignalSettings from "../../../services/signalProvider";
import { useNavigate } from "react-router-dom";
import { baseEnv } from "../../../App";
import { useTranslation } from "react-i18next";
import {
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { mainBox } from "../../../assets/Variables/SpaceVariables";
import TableComponent from "../../Generals/Tables/TableComponent";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader";
import Switch from "../../Generals/Buttons/Switch";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import * as spaceVariables from "../../../assets/Variables/SpaceVariables";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import SearchIcon from "@mui/icons-material/Search";
import DateSelector from "../../Generals/Selectors/DateSelector";
import NotificationModal from "../../Generals/Notifications/NotificationModal";
import Button3px from "../../Generals/Buttons/3pxButton";
import * as styles from "./Styles";
import { getLocalTimeZoneOffsetInSeconds } from "../../../Tools/DateTimeConvertions";
import dayjs from "dayjs";
import { useDynamicStyles } from "./Styles";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import StatusModule from "../../Generals/Cards/StatusModule";
//#endregion

function SignalOrders(props) {
  //#region "Instantiation of States"
  const styles = useDynamicStyles();
  const { isXs, isMd } = useBreakpoint();
  const navigate = useNavigate();
  const [openOrdersBtn, setOpenOrdersBtn] = useState(true);
  const [openOrdersData, setOpenOrdersData] = useState("");
  const [closedOrdersData, setClosedOrdersData] = useState("");
  const [totalAmount, SetTotalAmount] = useState();
  const [page, setPage] = useState(1);
  const [totalOrdersRows, setTotalOrdersRows] = useState(0);
  const [quantityPerPage, setQuantityPerPage] = useState(10);
  const [modalSettings, setModalSettings] = useState({});
  const [t] = useTranslation("global");
  const [pageCount, setPageCount] = useState(0); // Estado para el número de páginas
  const [rows, setRows] = useState([]); // Estado para los datos de las filas
  const [loading, setLoading] = useState([false]);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);

  const [toDate, setToDate] = useState(null);
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onAccept: undefined,
  });

  //Switch
  const [selectedSwitch, setSelectedSwitch] = useState("openTrades");

  //#endregion

  //#region "Declaration of Normal Variables or Constants"
  const columnsOpen = [
    {
      field: "ticket",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Ticket")}</>,
    },
    {
      field: "open_time",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Open Time")}</>,
    },
    {
      field: "type",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Type")}</>,
    },
    {
      field: "symbol",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Symbol")}</>,
    },
    {
      field: "volume",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Volume")}</>,
    },
    {
      field: "open_price",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Price")}</>,
    },
    {
      field: "sl",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Stop Loss")}</>,
    },
    {
      field: "tp",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Take Profit")}</>,
    },
    {
      field: "commission",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Commission")}</>,
    },
    {
      field: "swap",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Swap")}</>,
    },
    {
      field: "pnl",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Profit/Loss")}</>,
    },
    {
      field: "comment",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Comment")}</>,
    },
  ];

  const columnsClosed = [
    {
      field: "ticket",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Ticket")}</>,
    },
    {
      field: "open_time",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Open Time")}</>,
    },
    {
      field: "type",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Type")}</>,
    },
    {
      field: "symbol",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Symbol")}</>,
    },
    {
      field: "volume",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Volume")}</>,
    },
    {
      field: "open_price",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Price")}</>,
    },
    {
      field: "sl",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Stop Loss")}</>,
    },
    {
      field: "tp",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Take Profit")}</>,
    },
    {
      field: "close_time",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Close Time")}</>,
    },
    {
      field: "close_price",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Close Price")}</>,
    },
    {
      field: "commission",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Commission")}</>,
    },
    {
      field: "swap",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Swap")}</>,
    },
    {
      field: "pnl",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Profit/Loss")}</>,
    },
    {
      field: "comment",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Comment")}</>,
    },
  ];
  //#endregion

  //#region "Handlers"
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page);
    GetSignalClosedOrders(quantityPerPage, page, search, fromDate, toDate);
  };

  const TriggerNavbar = (value) => {
    setSelectedSwitch(value);
    setLoading(true); // Mostrar el spinner mientras se cargan los datos

    if (value === "openTrades") {
      GetSignalOpenOrders(quantityPerPage, page);
      setOpenOrdersBtn(true);
    } else if (value === "closedTrades") {
      GetSignalClosedOrders(quantityPerPage, page, search, fromDate, toDate);
      setOpenOrdersBtn(false);
    }
  };

  const GetSignalOpenOrders = async (quantityPerPage, page, search = "") => {
    try {
      setLoading(true);
      const response = await SignalSettings.LoadSignalOpenOrders(
        quantityPerPage,
        page,
        search
      );

      if (response.status === 200) {
        setLoading(false); //Stops loading spinning
        setOpenOrdersData(response.data.data.data); //If response is correct, sets ip into the hook ip.

        setTotalOrdersRows(response.data.data.total_registers); //If response is correct, sets ip into the hook ip.
        let actualRows = response.data.data.data.map((log, index) => {
          const dateOpen = new Date(log.open_time * 1000); // Convertimos segundos a milisegundos
          const year = dateOpen.getUTCFullYear();
          const month = String(dateOpen.getUTCMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
          const day = String(dateOpen.getUTCDate()).padStart(2, "0");
          const hours = String(dateOpen.getUTCHours()).padStart(2, "0");
          const minutes = String(dateOpen.getUTCMinutes()).padStart(2, "0");
          const secondsFormatted = String(dateOpen.getUTCSeconds()).padStart(
            2,
            "0"
          );

          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${secondsFormatted}`;

          let row = {
            id: index,
            ticket: log.ticket,
            open_time: formattedDate,
            type: log.type,
            symbol: log.symbol,
            volume: log.volume,
            open_price: log.open_price,
            sl: log.sl,
            tp: log.tp,
            commission: log.commission,
            swap: log.swap,
            pnl: log.pnl,
            comment: log.comment,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      } else {
        console.log("Endpoint get open orders list OK but not info");
        console.log(response);
      }
      return response.data;
    } catch (error) {
      if (error.response.data.message === "Login is required") {
        console.log(error);
        setLoading(false); //Stops loading spinning
        setModalProps({
          open: true,
          iconType: "error",
          title: "Session Expired",
          message:
            "Your session has expired, please log in again and try again.",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
        navigate(baseEnv + "/");
      } else {
        setLoading(false); //Stops loading spinning
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message:
            "An unexpected error has occurred, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    }
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };

  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const handleToDate = (date) => {
    setToDate(date);
  };
  const handleSearch = () => {
    setPage(0);
    if (openOrdersBtn) {
      GetSignalOpenOrders(10, 0, search);
    } else {
      console.log(fromDate);
      console.log(toDate);
      GetSignalClosedOrders(10, 0, search, fromDate, toDate);
    }
  };
  //#endregion

  //#region "Requests"
  const GetSignalClosedOrders = async (
    quantityPerPage,
    page,
    search = "",
    fromDate,
    toDate
  ) => {
    try {
      setLoading(true);
      const dateFromSeconds =
        fromDate / 1000 + getLocalTimeZoneOffsetInSeconds();
      const dateToSeconds =
        toDate / 1000 + getLocalTimeZoneOffsetInSeconds() + 86400;
      const response = await SignalSettings.LoadSignalClosedOrders(
        quantityPerPage,
        page,
        search,
        dateFromSeconds,
        dateToSeconds
      );

      if (response.status === 200) {
        setLoading(false); //Stops loading spinning
        setClosedOrdersData(response.data.data.data); //If response is correct, sets ip into the hook ip.
        setTotalOrdersRows(response.data.data.total_registers); //If response is correct, sets ip into the hook ip.
        let actualRows = response.data.data.data.map((log, index) => {
          const dateOpen = new Date(log.open_time * 1000); // Convertimos segundos a milisegundos
          const yearOpen = dateOpen.getUTCFullYear();
          const monthOpen = String(dateOpen.getUTCMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
          const dayOpen = String(dateOpen.getUTCDate()).padStart(2, "0");
          const hoursOpen = String(dateOpen.getUTCHours()).padStart(2, "0");
          const minutesOpen = String(dateOpen.getUTCMinutes()).padStart(2, "0");
          const secondsFormattedOpen = String(
            dateOpen.getUTCSeconds()
          ).padStart(2, "0");
          const formattedDateOpen = `${yearOpen}-${monthOpen}-${dayOpen} ${hoursOpen}:${minutesOpen}:${secondsFormattedOpen}`;

          const dateClose = new Date(log.close_time * 1000); // Convertimos segundos a milisegundos
          const yearClose = dateClose.getUTCFullYear();
          const monthClose = String(dateClose.getUTCMonth() + 1).padStart(
            2,
            "0"
          ); // Los meses van de 0 a 11
          const dayClose = String(dateClose.getUTCDate()).padStart(2, "0");
          const hoursClose = String(dateClose.getUTCHours()).padStart(2, "0");
          const minutesClose = String(dateClose.getUTCMinutes()).padStart(
            2,
            "0"
          );
          const secondsFormattedClose = String(
            dateClose.getUTCSeconds()
          ).padStart(2, "0");
          const formattedDateClose = `${yearClose}-${monthClose}-${dayClose} ${hoursClose}:${minutesClose}:${secondsFormattedClose}`;

          let row = {
            id: index,
            ticket: log.ticket,
            open_time: formattedDateOpen,
            type: log.type,
            symbol: log.symbol,
            volume: log.volume,
            open_price: log.open_price,
            sl: log.sl,
            tp: log.tp,
            close_time: formattedDateClose,
            close_price: log.close_price,
            commission: log.commission,
            swap: log.swap,
            profit: log.profit,
            comment: log.comment,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      } else {
        setLoading(false); //Stops loading spinning
      }
      return response.data;
    } catch (error) {
      if (error.response.data.message === "Login is required") {
        setLoading(false); //Stops loading spinning
        setModalProps({
          open: true,
          iconType: "error",
          title: "Session Expired",
          message:
            "Your session has expired, please log in again and try again.",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
        navigate(baseEnv + "/");
      } else {
        setLoading(false); //Stops loading spinning
        setClosedOrdersData("");
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message:
            "An unexpected error has occurred, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
        //props.AlertMessage("signalProvider.Server error, try again", "error", "red"); //Specific error message, validates if it's a periodic request
      }
    }
  };

  //#endregion

  //#region "useEffects"
  useEffect(() => {
    setFromDate(dayjs().startOf("day"));
    setToDate(dayjs().add(1, "day").startOf("day"));
    GetSignalOpenOrders(quantityPerPage, page);
  }, []);
  //#endregion

  //#region "HTML"
  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
      />
      {isXs && <StatusModule></StatusModule>}
      <Paper elevation={2} sx={styles.mainPaper}>
        <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
          {t("follower.Orders")}
        </Typography>
        {/* Box Padre de los botones de open y close */}
        <Box
          sx={{
            display: "flex",
            height: spaceVariables.Spaces.Size_13,
            padding: "0px 36px",
            justifyContent: "center",
            alignItems: "center",
            gap: spaceVariables.Spaces.Size_13,
            alignSelf: "stretch",
            borderRadius: spaceVariables.Spaces.Size_3,
            border: `1px solid ${Colors.Secondary_50}`,
          }}
        >
          <Switch
            label={t("follower.Open orders")}
            name="openTrades"
            selector={selectedSwitch}
            HandleOnClick={(e) => TriggerNavbar("openTrades")}
          />
          <Switch
            label={t("follower.Close orders")}
            name="closedTrades"
            selector={selectedSwitch}
            HandleOnClick={(e) => TriggerNavbar("closedTrades")}
          />
        </Box>
        <Box sx={styles.searchContainer}>
          {selectedSwitch === "closedTrades" && (
            <Box sx={styles.dateInputBox}>
              <DateSelector
                title={t("follower.From")}
                value={fromDate}
                handleChange={handleFromDate}
              ></DateSelector>
              <DateSelector
                title={t("follower.To")}
                value={toDate}
                handleChange={handleToDate}
              ></DateSelector>
            </Box>
          )}
          <Box sx={styles.searchButtonContainer}>
            <TextField
              value={search}
              onChange={handleSearchChange}
              placeholder={t("follower.Search order")}
              sx={styles.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      marginRight: "0px",
                    }}
                  >
                    <SearchIcon padding={0} />
                  </InputAdornment>
                ),
                sx: {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Elimina el borde
                  },
                },
              }}
            />
            <Button3px
              label={"Search"}
              color={Colors.Secondary_700}
              backgroundColor={Colors.Gray_200}
              HandleOnClick={handleSearch}
            ></Button3px>
          </Box>
        </Box>
        <TableComponent
          sx={{ width: "100%" }}
          rows={rows}
          page={page}
          totalAmount={totalAmount}
          pageCount={pageCount}
          handleChangePage={handleChangePage}
          columns={
            selectedSwitch === "closedTrades" ? columnsClosed : columnsOpen
          }
        />
      </Paper>
    </Box>
  );
  //#endregion
}

export default SignalOrders;
