import { Button, Typography } from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";

function TextIconButton({ HandleOnClick, logo, label, background = "rgba(255, 255, 255, 0)", color = Colors.Secondary_900}) {
  console.log(color);
  return (
    <Button
      onClick={HandleOnClick}
      sx={{
        backgroundColor: background,
        color: color,
        minWidth: "0px",
        textTransform: "none",
        padding: `${Spaces.Size_3}`,
        gap: Spaces.Size_2,
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${Colors.Secondary_900}`,
        borderRadius: Spaces.Size_2,
        lineHeight: "1",
        "&:hover": {
          backgroundColor: background, // Cambia el color al hacer hover
          opacity: 0.8, // Aplica opacidad al hacer hover
        },
      }}
    >
      {logo !== undefined &&       <span
        className="material-symbols-outlined"
        style={{ fontSize: Spaces.Size_3, lineHeight: 1 }}
      >
        {logo}
      </span>}

      <Typography sx={{fontFamily: TextVariables.Caption_1_Bold, lineHeight: 1}}>{label}</Typography>
    </Button>
  );
}

export default TextIconButton;
